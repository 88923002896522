import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../css/custom.css";
import "../../App.css";
//import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
// Import Materialize
import M from "materialize-css";
import UserService from "../../services/UserService";
import { setUser } from "../../services/auth";

export class eatc_alim_aut extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      password: '',
      error_userName: 'Proporcione un correo electrónico!',
      error_password: 'Proporcione un número de identificación!',
      error_api: '',
      infoVolunteer: []
    }
  }

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    this.userName.focus();
  }

  onSubmit = (e) => {
    console.log("Enviando...");
    e.preventDefault();
    this.setState({
      error_api: ""
    });
    const { userName, password } = this.state;
    if (!userName) {
      this.setState({ error_userName: "Proporcione un correo electrónico!" });
      this.userName.focus();
    } else {
      this.setState({ error_userName: "" });
    }
    if (!password) {
      this.setState({ error_password: "Proporcione un número de identificación!" });
      this.password.focus();
    } else {
      this.setState({ error_password: "" });
    }

    if (!userName || !password) {
    } else {
      //this.props.addTask(this.state.title, this.state.description);
      UserService.getVolunteer(this.state.userName, this.state.password)
        .then(infoVolunteer => {
          console.log("Ocurrió un exito -> ", infoVolunteer);
          this.setState({
            infoVolunteer: infoVolunteer
          });
          setUser(infoVolunteer);
          this.redirectToTarget('/codigo');
        }
        )
        .catch(err => {
          console.log("Ocurrió un error -> ", err);
          this.setState({
            error_api:
              "Email o número de identificación incorrectos,\n verifique sus datos e intentelo nuevamente"
          });
          /*let errorRaciones = { _id: "0", dia: this.formatDate(), raciones_acumuladas_alimentaton: "0" };
          console.log("this.state.rations ", this.state.rations);
          this.setState({
            rations: [...this.state.rations, errorRaciones]
          })*/
        });
    }

  }

  redirectToCodigo = () => {
    console.log("redirect");
    this.props.history.push({
      pathname: "/codigo"
    });
  };

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  onChange = e => {
    console.log(e.target.value);
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const errorName = 'error_' + name;
    console.log('target ', target);
    console.log('name ', name);
    console.log('value ', value);
    console.log('errorName ', errorName);
    this.setState({
      [name]: value,
      [errorName]: ""
    })
    /* this.setState({
      [e.target.name]: e.target.value
    }) */
  }

  render() {

    /* const errorStyle = {
      color: 'red',
      textShadow: '1px 1px black'
    } */

    const titleShadow = {
      textShadow: '1px 1px black'
    }

    return (
      <div id="login-alimentaton" className="back-structure">
        <div className="container-login">
          <div className="row">
            <div className="col s12 m10 offset-m1 l4 offset-l4">
              <h1 className="tit-2" style={titleShadow}>
                Ahora puedes ingresar a nuestra
              <span className="txt-fucsia"> APP </span><br />
                para realizar cambios en el voluntariado o seguir donando.
              </h1>
              <h3 style={titleShadow}>
                ¡La
                <span className="txt-fucsia"> comida </span>se disfruta más<br />
                cuando la <span className="txt-fucsia">compartes</span>!
                {/* <br />
                <span className="txt-fucsia">Juntos</span> alimentaremos <br />a{" "}
                <span className="txt-fucsia">miles de familias</span>
                <br />
                en toda Colombia! */}
              </h3>
            </div>
          </div>
          {/* <!-- usr login --> */}
          <div className="row">
            <form className="col s12 m10 offset-m1 l4 offset-l4" onSubmit={this.onSubmit}>
              <div className="row">
                <div className="input-field col s12">
                  <input id="usr_name" name='userName' type="email"
                    autoComplete="email" className="validate"
                    ref={(input) => { this.userName = input; }}
                    onChange={this.onChange}
                    value={this.state.userName} />
                  <label htmlFor="usr_name">Correo eléctronico</label>
                  {this.state.error_userName &&
                    <p className="txt-fucsia">{this.state.error_userName}</p>}
                </div>
              </div>
              <div className="row">
                <div className="input-field col s12">
                  <input id="password" type="password" autoComplete="new-password"
                    className="validate" name='password'
                    ref={(input) => { this.password = input; }}
                    onChange={this.onChange}
                    value={this.state.password} />
                  <label htmlFor="password">Número de identificación</label>
                  {this.state.error_password &&
                    <p className="txt-fucsia">{this.state.error_password}</p>}
                  {/* <span
                    className="helper-text"
                    data-error="wrong"
                    data-success="right"
                  >
                    Nombre de usuario o Contraseña incorrecta
                  </span> */}
                </div>
              </div>
              {/* <input type='submit' className="waves-effect waves-light btn-large accbtn btn-text-overflow" value="Registrate"/> */}
              <div className="row">
                {this.state.error_api &&
                  <p className="txt-fucsia">{this.state.error_api}</p>}
                <button
                  type='submit'
                  className="waves-effect waves-light btn-large accbtn btn-text-overflow">
                  Login
                </button>
              </div>
            </form>
            <div className="row">
              <button
                className="waves-effect waves-light btn-large accbtn btn-violeta btn-text-overflow"
                onClick={() => { this.redirectToTarget('/registro') }}>
                Registrate
              </button>
              {/* <Link
                to="/registro"
                className="waves-effect waves-light btn-large accbtn btn-violeta btn-text-overflow">
                Registrate
              </Link> */}
            </div>
          </div>
          <div className="row">
            <div className="col s12 m10 offset-m1 l4 offset-l4">
              <button
                className="waves-effect waves-teal btn-flat accbtn btn-text-overflow"
                onClick={() => { this.redirectToTarget('/terminos') }}>
                Términos de servicio
              </button>
              {/* <Link
                to="/terminos"
                className="waves-effect waves-teal btn-flat accbtn">
                Términos de servicio
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default eatc_alim_aut;
