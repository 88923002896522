import React, { Component, useState } from "react"; //forwardRef
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../App.css";
import "../../css/custom.css"
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work
import $ from 'jquery';
// import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
// Import Materialize
import M from "materialize-css";
import PointDonationService from "../../services/PointDonationService";
import { isUser, getUser, setUser, isCitied, getCitied } from "../../services/auth";
import HelpService from "../../services/HelpService";
import UserService from "../../services/UserService";
import RegisterService from "../../services/RegisterService";
import Preloader from '../preloader/Preloader';

import SweetAlert from 'sweetalert2-react';
//import {SweetAlertRegister, SweetAlertUpdate} from 'sweetalert2-react';
//import { withSwalInstance } from 'sweetalert2-react';
//import swal from 'sweetalert2';

import DatePicker, { registerLocale } from 'react-datepicker';
import { addDays, getDay } from 'date-fns';
import es from "date-fns/locale/es";

import 'react-datepicker/dist/react-datepicker.css';
//import 'bootstrap/dist/css/bootstrap.min.css';

export class eatc_alim_vol extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minInitialHour: "0",
      maxInitialHour: "23",
      minFinalHour: "0",
      maxFinalHour: "23",
      initialHour: "",
      finalHour: "",
      sweetAlertTitle: "",
      sweetAlertText: "",
      sweetAlertRegister: false,
      sweetAlertUpdate: false,
      sweetAlertInfo: false,
      showProgressBar: false,
      showProgressBar2: false,
      showProgressBar3: false,
      showFuncionDatepicker: true,
      currentPoint: [],
      currentPointDate: "2019-10-22",
      currentPointDays: "sa,do",
      readOnlyDate: true,
      readOnlyTimeMin: true,
      readOnlyTimeMax: true,
      points: [],
      infoMunicipios: [],
      infoVolunteer: [],
      fecha_inicio_la_atencin_o_vinculacion: "",
      tipo_documento: "",
      error_tipo_documento: "Seleccione un tipo de identificación!",
      numero_documento: "",
      error_numero_documento: "Proporcione un número de identificación!",
      nombre_apellido: "",
      error_nombre_apellido: "",
      primer_nombre: "",
      error_primer_nombre: "Proporcione un primer nombre!",
      segundo_nombre: "",
      error_segundo_nombre: "",
      primer_apellido: "",
      error_primer_apellido: "Proporcione un primer apellido!",
      segundo_apellido: "",
      error_segundo_apellido: "",
      edad: "",
      error_edad: "",
      sexo: "",
      error_sexo: "",
      municipio_residencia: "Proporcione un municipio de residencia!",
      error_municipio_residencia: "",
      numero_telefonico: "",
      error_numero_telefonico: "Proporcione un número telefónico!",
      correo_electronico: "",
      error_correo_electronico: "Proporcione un correo electrónico!",
      lugar_voluntariado: "",
      error_lugar_voluntariado: "Proporcione un lugar de voluntariado!",
      dias: "",
      error_dias: "Proporcione un dia para el voluntariado!",
      horario_inicio: "",
      error_horario_inicio: "Proporcione una hora de inicio!",
      horario_fin: "",
      error_horario_fin: "Proporcione un hora de finalización!",
      horario: 0,
      email: "",
      password: "",
      error: "",
      value: ""
    };
    this.options = ["Bogotá", "Medellín", "Cali"];
    this.optionsMinus = ["bogota", "medellin", "cali"];
    this.setValue = this.setValue.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getAllDonationPoints = this.getAllDonationPoints.bind(this);
    this.getAllDonationPointsCity = this.getAllDonationPointsCity.bind(this);

  }

  FuncionDatepicker = (props) => {
    registerLocale("es", es);
    const [startDate, setStartDate] = useState(); //addDays(new Date(props.initialDay), 1)
    const finalDay = addDays(new Date(props.initialDay), 6);
    const days = props.activeDays.split(",");
    const dayNumber = []
    days.forEach(function (day) {
      //console.log("element -> ", day);
      switch (day) {
        case "do":
          dayNumber.push(0);
          break;
        case "lu":
          dayNumber.push(1);
          break;
        case "ma":
          dayNumber.push(2);
          break;
        case "mi":
          dayNumber.push(3);
          break;
        case "ju":
          dayNumber.push(4);
          break;
        case "vi":
          dayNumber.push(5);
          break;
        case "sa":
          dayNumber.push(6);
          break;
        default:
          break;
      }
    });
    //console.log("dayNumber -> ", dayNumber);

    const isWeekday = date => {
      const dayX = getDay(date);
      for (let i = 0; i < dayNumber.length; i++) {
        //console.log("i -> ", i);
        if (dayX === dayNumber[i])
          return true;
      }
      //return dayX === dayNumber[0] || dayX === dayNumber[1];
    };

    /* const ExampleCustomButton = forwardRef((props, ref) => {
      return <button id="example-custom-input-date" className="example-custom-input"
        onChange={props.onChange}
        onClick={props.onClick}
        ref={ref}>{props.value}</button>;
    }); */

    const handleChangeRaw = (date) => {
      console.log("date ", date);
      let s = document.getElementById(props.id)
      console.log("s ", s);
      //s.value =moment(this.props.input.value).format("DD/MM/YYYY");
    }

    /* const onClick = () => {
      console.log("onClick -> ");
    } */

    const onChange = (e) => {
      console.log("F...FuncionDatepicker onChange -> ", e);
      let day = moment(new Date(e)).locale('es').format('dddd');
      console.log("day ", day);
      /*this.setState({
        days: day,
      })*/
      this.setState({
        dias: day,
        error_dias: "",
        readOnlyTimeMin: false,
      })
      return (setStartDate(e));
    }

    /* const ExampleCustomInput = ({ value, onClick }) => (
      <button className="example-custom-input" onClick={onClick}>
        {value}
      </button>
    ); */
    return (
      <div>
        <label className="active">{props.label}</label>
        <DatePicker
          onChangeRaw={(e) => handleChangeRaw(e)}
          placeholderText="Click to select a date"
          selected={startDate}
          onChange={(e) => onChange(e)}
          //onChange={date => setStartDate(date)}
          dateFormat="yyyy-MM-dd"
          locale="es"
          filterDate={isWeekday}
          minDate={new Date(props.initialDay)}
          maxDate={finalDay}
          showDisabledMonthNavigation
          name={props.name}
          id={props.id}
          readOnly={props.readOnly}
          disabledKeyboardNavigation
        //customInput={<ExampleCustomButton />}
        />
      </div>
    )
  }

  FuncionTimepickerMin = (props) => {
    registerLocale("es", es);
    const minDate = new Date();
    minDate.setMinutes(0);
    minDate.setHours(props.min);
    const maxDate = new Date();
    maxDate.setMinutes(0);
    maxDate.setHours(props.max);
    const [startDate, setStartDate] = useState();

    const onChange = (e) => {
      console.log("onChange -> ", e);
      let initialHour = moment(new Date(e)).locale('es').format('LTS');
      console.log("initialHour ", initialHour);
      let horarioInicio = initialHour.split(":", 1).toString();
      var minHorarioFin = (parseInt(horarioInicio) + 1).toString();
      console.log("minHorarioFin ", typeof (minHorarioFin)); // number.typeOf);
      this.setState({
        //initialHour: initialHour,
        horario_inicio: initialHour,
        error_horario_inicio: "",
        minFinalHour: minHorarioFin,
        readOnlyTimeMax: false,
      })
      return (setStartDate(e));
    }

    const handleChangeRaw = (date) => {
      let s = document.getElementById(props.id)
      console.log("s ", s);
      //s.value =moment(this.props.input.value).format("DD/MM/YYYY");
    }

    return (
      <div>
        <label className="active">{props.label}</label>
        <DatePicker
          showTimeSelect
          showTimeSelectOnly
          disabledKeyboardNavigation
          onChangeRaw={(e) => handleChangeRaw(e)}
          id={props.id}
          timeIntervals={60}
          selected={startDate}
          //onChange={date => (setStartDate(date))}
          //onChange={this.onChange}
          onChange={(e) => onChange(e)}
          name={props.name}
          timeCaption="Time"
          dateFormat="hh:mm aa"
          minTime={minDate}
          maxTime={maxDate}
          readOnly={props.readOnly}
        />
      </div>
    )
  }

  FuncionTimepickerMax = (props) => {
    registerLocale("es", es);
    const minDate = new Date();
    minDate.setMinutes(0);
    minDate.setHours(props.min);
    const maxDate = new Date();
    maxDate.setMinutes(0);
    maxDate.setHours(props.max);
    //const [startDate, setStartDate] = useState(minDate);
    const [endDate, setEndDate] = useState();

    const onChange = (e) => {
      console.log("onChange -> ", e);
      let finalHour = moment(new Date(e)).locale('es').format('LTS');
      console.log("finalHour ", finalHour);
      this.setState({
        //finalHour: finalHour,
        horario_fin: finalHour,
        error_horario_fin: "",
      })
      return (setEndDate(e));
    }

    const handleChangeRaw = (date) => {
      let s = document.getElementById(props.id)
      console.log("s ", s);
      //s.value =moment(this.props.input.value).format("DD/MM/YYYY");
    }

    return (
      <div>
        <label className="active">{props.label}</label>
        <DatePicker
          showTimeSelect
          showTimeSelectOnly
          disabledKeyboardNavigation
          onChangeRaw={(e) => handleChangeRaw(e)}
          id={props.id}
          timeIntervals={60}
          selected={endDate}
          //onChange={date => (setStartDate(date))}
          //onChange={this.onChange}
          onChange={(e) => onChange(e)}
          name={props.name}
          timeCaption="Time"
          dateFormat="hh:mm aa"
          minTime={minDate}
          maxTime={maxDate}
          readOnly={props.readOnly}
        />
      </div>
    )
  }

  setValue(value) {
    console.log("value", value)
    this.setState({ value });
  }

  handleChange(e) {
    console.log(e);
    this.setValue(e.target.value);
    switch (e.target.value) {
      case "0":
        console.log("0");
        break;

      case "1":
        console.log("1");
        this.getAllDonationPoints(this.optionsMinus[1 - 1]);
        break;

      case "2":
        console.log("2");
        this.getAllDonationPoints(this.optionsMinus[2 - 1]);
        break;

      case "3":
        console.log("3");
        this.getAllDonationPoints(this.optionsMinus[3 - 1]);
        break;

      default:
        console.log("default");
        break;
    }
  }

  componentDidMount() {
    console.log("...ESTAS EN /voluntarios");
    M.AutoInit();
    M.updateTextFields();
    this.tipo_documento.focus();
    //this.getMunicipios();
    this.getMunicipiosDonations();
    $("#fecha").attr('maxlength', '0');
    $("#horario_inicio").attr('maxlength', '0');
    $("#horario_fin").attr('maxlength', '0');
    var elementDate = $('.datepicker');
    var elementTime = $('.timepicker');
    var optionsDate = {
      //autoClose: true,
      firstDay: true,
      minDate: new Date(this.state.currentPointDate),
      selectYears: 1,
      selectMonths: false,
      showClearBtn: true,
      format: 'yyyy-mm-dd',
      disableDayFn: function (date) {
        if (date.getDay() === 1)
          return false;
        else
          return true;
      },
      //format: 'dddd',
      i18n: {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Set", "Oct", "Nov", "Dic"],
        weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        weekdaysShort: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
        weekdaysAbbrev: ["D", "L", "M", "M", "J", "V", "S"],
        selectMonths: false,
        selectYears: 1,
        cancel: 'Cancelar',
        clear: 'Limpiar',
        done: 'OK'
      }
    };
    var optionsTime = {
      //autoClose: true,
      showClearBtn: true,
      twelveHour: false,
      format: 'hh:00:00',
      i18n: {
        cancel: 'Cancelar',
        clear: 'Limpiar',
        done: 'OK'
      }
    };
    M.Datepicker.init(elementDate, optionsDate);
    M.Timepicker.init(elementTime, optionsTime);
    //var instance = M.Datepicker.getInstance(elementDate);
    //instance.open();

    var onChangeMaterialize = (name, value) => {
      console.log(value);
      this.setState({
        [name]: value,
        ['error_' + name]: ""
      })
    }

    $('.datepicker').on('change', function () {
      console.log('change: ' + this.value);
      onChangeMaterialize(this.name, this.value);
      //console.log(moment(new Date(this.value)).format('dd'));
    })

    $('.timepicker').on('change', function () {
      console.log('change: ' + this.value);
      console.log('change: ' + this.name);
      let receivedVal = $(this).val().split(":", 1);
      $(this).val(receivedVal + ":00:00");
      onChangeMaterialize(this.name, this.value);
    })

    /*$('.timepicker').timepicker({
      onSelect: function(time) {
        console.log(time)
      }
    });
    
    $('.datepicker').datepicker({
      onSelect: function(time){
        console.log(time)
      }
    });*/


    if (isUser()) {
      var Objvolunteer = JSON.parse(getUser());
      var volunteer = Objvolunteer[0];
      this.setState({
        infoVolunteer: Objvolunteer,
        numero_documento: volunteer['numero_documento'],
        error_numero_documento: "",
        tipo_documento: volunteer['tipo_documento'],
        error_tipo_documento: "",
        primer_nombre: volunteer['primer_nombre'],
        error_primer_nombre: "",
        segundo_nombre: volunteer['segundo_nombre'],
        error_segundo_nombre: "",
        primer_apellido: volunteer['primer_apellido'],
        error_primer_apellido: "",
        segundo_apellido: volunteer['segundo_apellido'],
        error_segundo_apellido: "",
        edad: volunteer['edad'],
        error_edad: "",
        sexo: volunteer['sexo'],
        error_sexo: "",
        municipio_residencia: volunteer['municipio_residencia'],
        error_municipio_residencia: "",
        numero_telefonico: volunteer['numero_telefonico'],
        error_numero_telefonico: "",
        correo_electronico: volunteer['correo_electronico'],
        error_correo_electronico: "",
        //lugar_voluntariado: volunteer['lugar_voluntariado'],
        //error_lugar_voluntariado: "",
        //dias: volunteer['dias'],
        //error_dias: "",
      })
      this.getAllDonationPointsCity(volunteer['municipio_residencia'])
    } else {
      console.log(isCitied());
      if (isCitied()) {
        console.log(getCitied());
        this.setState({
          municipio_residencia: getCitied(),
        })
        this.getAllDonationPointsCity(getCitied())
      } else {
        this.getAllDonationPoints();
      }
    }
  }

  /*handleSignIn = async e => {
    e.preventDefault();
    const { email, password } = this.state;
    if (!email || !password) {
      this.setState({ error: "Preencha e-mail e senha para continuar!" });
    } else {
      try {
        const response = await api.post("/sessions", { email, password });
        login(response.data.token);
        this.props.history.push("/app");
      } catch (err) {
        this.setState({
          error:
            "Houve um problema com o login, verifique suas credenciais. T.T"
        });
      }
    }
  };*/

  updateUser = () => {
    UserService.getVolunteer(this.state.correo_electronico, this.state.numero_documento)
      .then(infoVolunteer => {
        console.log("Ocurrió un exito -> ", infoVolunteer);
        setUser(infoVolunteer);
        this.setState({
          infoVolunteer: infoVolunteer,
          show: true,
          sweetAlertRegister: false,
          sweetAlertUpdate: true,
          sweetAlertInfo: false,
          sweetAlertTitle: "Registro Actualizado",
          sweetAlertText: "El registro se actualizó correctamente \n con la información de los horarios de voluntariado",
        })
      }
      )
      .catch(err => {
        console.log("Ocurrió un error -> ", err);
      });
  }

  getAllDonationPoints() {
    console.log("getAllDonationPoints");
    this.setState({
      showProgressBar2: !this.state.showProgressBar2
    });
    PointDonationService.getAll()
      .then(points => {
        console.log("Ocurrió un exito (PUNTOS)-> ", points);
        this.setState({
          showProgressBar2: !this.state.showProgressBar2,
          points: points
        })
      }
      )
      .catch(err => console.log("Ocurrió un error", err));
  };

  onConfirm = () => {
    if (this.state.sweetAlertRegister) {
      this.setState({
        show: false
      });
      setTimeout(this.redirectToTarget('/login'), 3000);
    } else if (this.state.sweetAlertUpdate) {
      this.setState({
        show: false
      });
      setTimeout(this.redirectToTarget('/dashboard'), 3000);
    }
    else if (this.state.sweetAlertInfo) {
      this.setState({
        show: false
      });
      console.log("SOLO INFORMACION ");
    }
  }

  onConfirmDashboard = () => {
    this.setState({
      show: false
    });
    setTimeout(this.redirectToTarget('/dashboard'), 1000);
  }

  onConfirmLogin = () => {
    this.setState({
      show: false
    });
    setTimeout(this.redirectToTarget('/login'), 1000);
  }

  handleSignUp = e => {
    e.preventDefault();
    //console.log("lugar voluntariado ", this.getLugarVoluntariado());
    //console.log("dias voluntariado ", this.getDiasVoluntariado());
    //console.log("inicio voluntariado ", this.getHorarioInicioVoluntariado());
    //console.log("fin voluntariado ", this.getHorarioFinVoluntariado());

    /*const { tipo_documento, numero_documento, primer_nombre,
      primer_apellido, municipio_residencia, correo_electronico } = this.state;*/
    const { tipo_documento, numero_documento, primer_nombre, segundo_nombre,
      primer_apellido, segundo_apellido, edad, sexo, municipio_residencia,
      numero_telefonico, correo_electronico, lugar_voluntariado, dias, horario_inicio, horario_fin } = this.state;
    if (!horario_fin) {
      this.setState({ error_horario_fin: "Proporcione un hora de finalización!" });
    } else {
      this.setState({ error_horario_fin: "" });
    }
    if (!horario_inicio) {
      this.setState({ error_horario_inicio: "Proporcione una hora de inicio!" });
    } else {
      this.setState({ error_horario_inicio: "" });
    }
    if (!dias) {
      this.setState({ error_dias: "Proporcione un dia para el voluntariado!" });
    } else {
      this.setState({ error_dias: "" });
    }
    if (!lugar_voluntariado) {
      this.setState({ error_lugar_voluntariado: "Proporcione un lugar de voluntariado!" });
      this.lugar_voluntariado.focus();
    } else {
      this.setState({ error_lugar_voluntariado: "" });
    }
    if (!correo_electronico) {
      this.setState({ error_correo_electronico: "Proporcione un correo electrónico!" });
      this.correo_electronico.focus();
    } else {
      this.setState({ error_correo_electronico: "" });
    }
    if (!numero_telefonico) {
      this.setState({ error_numero_telefonico: "Proporcione un número telefónico!" });
      this.numero_telefonico.focus();
    } else {
      this.setState({ error_numero_telefonico: "" });
    }
    if (!municipio_residencia) {
      this.setState({ error_municipio_residencia: "Proporcione un municipio de residencia!" });
      this.municipio_residencia.focus();
    } else {
      this.setState({ error_municipio_residencia: "" });
    }
    if (!primer_apellido) {
      this.setState({ error_primer_apellido: "Proporcione un primer apellido!" });
      this.primer_apellido.focus();
    } else {
      this.setState({ error_primer_apellido: "" });
    }
    if (!primer_nombre) {
      this.setState({ error_primer_nombre: "Proporcione un primer nombre!" });
      this.primer_nombre.focus();
    } else {
      this.setState({ error_primer_nombre: "" });
    }
    if (!numero_documento) {
      this.setState({ error_numero_documento: "Proporcione un número de identificación!" });
      this.numero_documento.focus();
    } else {
      this.setState({ error_numero_documento: "" });
    }
    if (!tipo_documento) {
      this.setState({ error_tipo_documento: "Seleccione un tipo de identificación!" });
      this.tipo_documento.focus();
    } else {
      this.setState({ error_tipo_documento: "" });
    }

    if (!tipo_documento || !numero_documento || !primer_nombre || !primer_apellido ||
      !municipio_residencia || !correo_electronico || !numero_telefonico ||
      !lugar_voluntariado || !dias || !horario_inicio || !horario_fin) {
    } else {
      if (isUser()) {
        // SI ES UN USUARIO LOGUEADO UPDATE Y VA A EL DASHBOARD
        console.log("lugar voluntariado ", this.getLugarVoluntariado());
        console.log("dias voluntariado ", dias);
        console.log("inicio voluntariado ", horario_inicio);
        console.log("fin voluntariado ", horario_fin);
        RegisterService.updateRegister(numero_documento, this.getLugarVoluntariado(), dias, horario_inicio, horario_fin)
          //RegisterService.setNewRegister("", "cédula", "71773955", "Jorge", "William", "Correa", "Toro", "43", "masculino", "Medellín", "3113581681", "jorgecorrea@nodrizza.com")
          .then(infoRegister => {
            console.log("Ocurrió un exito UPDATE -> ", infoRegister);
            this.updateUser();
          }
          )
          .catch(err => {
            console.log("Ocurrió un error -> ", err);
            this.setState({
              error:
                "Ocurrió un problema, verifique sus datos e intentelo nuevamente"
            });
          });
      } else {
        // SI NO ES UN USUARIO LOGUEADO SE REGISTRA Y VA A EL LOGIN A LOGUEARSE
        RegisterService.setNewRegister(moment().format("YYYY-MM-DD HH:mm:ss"),
          tipo_documento, numero_documento, primer_nombre, segundo_nombre,
          primer_apellido, segundo_apellido, edad, sexo, municipio_residencia,
          numero_telefonico, correo_electronico, this.getLugarVoluntariado(),
          dias, horario_inicio, horario_fin)
          //RegisterService.setNewRegister("", "cédula", "71773955", "Jorge", "William", "Correa", "Toro", "43", "masculino", "Medellín", "3113581681", "jorgecorrea@nodrizza.com")
          .then(infoRegister => {
            console.log("Ocurrió un exito -> ", infoRegister);
            //this.redirectToLogin();
            this.setState({
              show: true,
              sweetAlertRegister: true,
              sweetAlertUpdate: false,
              sweetAlertInfo: false,
              sweetAlertTitle: "Registro Realizado",
              sweetAlertText: "¡Felicitaciones! \nQuedaste inscrito como voluntario para la Alimentatón 2019.",
            })
          }
          )
          .catch(err => {
            console.log("Ocurrió un error -> ", err);
            this.setState({
              error:
                "Datos invalidos, verifique sus datos e intentelo nuevamente"
            });
          });
      }
    }
  };

  onChange = e => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const errorName = 'error_' + name;
    console.log('...ON CHANGE target ', target);
    console.log('...ON CHANGE name ', name);
    console.log('...ON CHANGE value ', value);
    console.log('...ON CHANGE errorName ', errorName);
    this.setState({
      [name]: value,
      [errorName]: ""
    })
    if (name === "municipio_residencia") {
      console.log('...LLAMA LA INFO DE ESE PUNTO ', name);
      console.log('...POINTS ', this.state.points);
      this.getAllDonationPointsCity(value)
    }
    if (name === "lugar_voluntariado") {
      console.log('...LLAMA LA INFO DE ESE PUNTO ', name);
      console.log('...POINTS ', this.state.points);
      this.setState({
        showProgressBar3: !this.state.showProgressBar3
      });
      const currentPoint = this.state.points.filter(point => point["eatc-id"] === value);
      const fechaHoraCP = currentPoint[0]["fecha"];
      const diasCP = currentPoint[0]["dias"];
      const horarioInicio = currentPoint[0]["horario_inicio"];
      let minHorarioInicio = horarioInicio.split(":", 1).toString();
      const horarioFin = currentPoint[0]["horario_final"];
      let maxHorarioInicio = horarioFin.split(":", 1).toString();
      let fechaCP = fechaHoraCP.split(" ", 1).toString();
      let fechaCPP = this.sumarUnDia(fechaHoraCP.split(" ", 1).toString());
      //fechaCP = '2019-11-16';
      console.log('...POINT ', currentPoint);
      console.log('...POINT FECHA/HORA ', fechaHoraCP);
      console.log('...POINT FECHA ', fechaCP);
      console.log('...POINT FECHA+1 ', fechaCPP);
      console.log('...POINT DIAS ', diasCP);
      console.log('...POINT HORARIO INICIO ', minHorarioInicio);
      console.log('...POINT HORARIO FIN ', maxHorarioInicio);
      this.setState({
        currentPoint: currentPoint,
        currentPointDate: fechaCP,  // FECHA NORMAL
        //currentPointDate: fechaCPP,   // FECHA NORMAL + 1
        currentPointDays: diasCP,
        readOnlyDate: false,
        minInitialHour: minHorarioInicio,
        maxInitialHour: maxHorarioInicio,
        minFinalHour: minHorarioInicio,
        maxFinalHour: maxHorarioInicio,
      })
      setTimeout(this.setValuesDates, 1000);
      //var myVar = setInterval(this.setValuesDates, 1000);
      //setInterval(this.setValuesDates(currentPoint, fechaCP, diasCP), 1000);
    }
  }

  sumarUnDia = (fecha) => {
    let arrayFecha = fecha.split("-");
    let day = parseInt(arrayFecha[2]) + 1;
    arrayFecha.splice(2, 1, day);
    return arrayFecha.join("-");
  }

  setValuesDates = () => {
    this.setState({
      showProgressBar3: !this.state.showProgressBar3
    })
    $("#fecha").attr('maxlength', '0');
    $("#horario_inicio").attr('maxlength', '0');
    $("#horario_fin").attr('maxlength', '0');
  }

  getLugarVoluntariado = () => {
    var lugaresVoluntariado = this.state.currentPoint;
    var lugarVol = lugaresVoluntariado[0];
    return lugarVol['eatc-name'];
  }

  getDiasVoluntariado = () => {
    var lugaresVoluntariado = this.state.currentPoint;
    var lugarVol = lugaresVoluntariado[0];
    return lugarVol['dias'];
  }

  getHorarioInicioVoluntariado = () => {
    var lugaresVoluntariado = this.state.currentPoint;
    var lugarVol = lugaresVoluntariado[0];
    return lugarVol['horario_inicio'];
  }

  getHorarioFinVoluntariado = () => {
    var lugaresVoluntariado = this.state.currentPoint;
    var lugarVol = lugaresVoluntariado[0];
    return lugarVol['horario_final'];
  }

  getMunicipios = () => {
    console.log("getMunicipios() -> ");
    this.setState({
      showProgressBar: !this.state.showProgressBar
    });
    HelpService.getAllMunicipios()
      .then(infoMunicipios => {
        console.log("Ocurrió un exito (MUNICIPIOS)-> ", infoMunicipios);
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          infoMunicipios: infoMunicipios
        });
        //this.redirectToCodigo();
      }
      )
      .catch(err => {
        console.log("Ocurrió un error (MUNICIPIOS)-> ", err);
        var infoMunicipios = [];
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          infoMunicipios: infoMunicipios
        });
      });
  }

  getMunicipiosDonations = () => {
    console.log('...OBTENGO TODOS LOS MUNICIPIOS CON PUNTO DE DONACION (ASINCRONO)');
    this.setState({
      showProgressBar: !this.state.showProgressBar
    });
    PointDonationService.getAll()
      .then(infoMunicipios => {
        console.log("@...OBTENGO MUNICIPIOS (EXITO) -> ", infoMunicipios);
        const distinctCities = [...new Set(infoMunicipios.map(x => x["eatc-city"]))];
        console.log("@...FILTRO MUNICIPIOS -> ", distinctCities);
        console.log("@...GUARDA EN EL STATE LOS MUNICIPIOS DONANTES");
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          infoMunicipios: distinctCities
        });
      }
      )
      .catch(err => {
        console.log("@...NO OBTUVE MUNICIPIOS (ERROR) -> ", err);
        var infoMunicipios = [];
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          infoMunicipios: infoMunicipios
        });
      });
  }

  getAllDonationPointsCity(city) {
    console.log('...OBTENGO TODOS LOS PUNTOS DE DONACION POR CIUDAD (', city, ') (ASINCRONO)');
    this.setState({
      showProgressBar2: !this.state.showProgressBar2
    });
    PointDonationService.getCity(city)
      .then(points => {
        console.log("@...OBTENGO PUNTOS (EXITO) -> ", points);
        console.log("@...GUARDA EN EL STATE LOS PUNTOS DE DONACION");
        this.setState({
          showProgressBar2: !this.state.showProgressBar2,
          points: points
        })
      }
      )
      .catch(err => {
        console.log("@...NO OBTUVE PUNTOS (ERROR) -> ", err);
        let points = [];
        console.log("@...GUARDA EN EL STATE PUNTOS (VACIO)");
        this.setState({
          showProgressBar2: !this.state.showProgressBar2,
          points: points
        });
      });
  };

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  redirectToTargetBack = () => {
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  redirectToDashboard = () => {
    console.log("redirect");
    this.props.history.push("/dashboard");
  };

  redirectToLogin = () => {
    console.log("redirect");
    this.props.history.push("/login");
  };

  orderAndFirstElementArray = (arr, item) => {
    arr.sort()
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    return arr.unshift(item);
  }

  render() {
    const divStyle = {
      fontSize: "xx-large"
    };
    /*const errorStyle = {
      color: 'red',
      textShadow: '1px 1px black'
    }*/
    this.orderAndFirstElementArray(this.state.infoMunicipios, 'Bogotá');
    const options = this.state.infoMunicipios.map((elem, index) => (
      <option key={index} value={elem}>
        {elem}
      </option>
    ));
    const optionsPlaces = this.state.points.map((elem, index) => (
      <option key={elem["_id"]} value={elem["eatc-id"]}>
        {elem["eatc-name"]}
      </option>
    ));
    //const SweetAlert = withSwalInstance(swal);
    return (
      <div>
        <div id="voluntarios" className="pagina">
          <div className="navbar-fixed">
            <nav>
              <div className="nav-wrapper">
                <h1 className="brand-logo logo-con-boton">
                  Donación de tiempo
                </h1>
                <ul id="nav-mobile" className="left">
                  <li>
                    {/* <button
                      className="accbtn"
                      onClick={() => { this.redirectToBack() }}>
                      <i className="material-icons fixed_eatc" style={divStyle}>
                        chevron_left</i>
                    </button> */}
                    <button
                      className="backButton accbtn"
                      onClick={() => { this.redirectToTargetBack() }}>
                      <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                    </button>
                    {/* <Link to="#" className="accbtn" onClick={() => { this.redirectToTargetBack() }}>
                      <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                    </Link> */}
                    {/* <Link to="/dashboard" className="accbtn">
                      <i className="material-icons fixed_eatc" style={divStyle}>
                        chevron_left
                      </i>
                    </Link> */}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="container">
            <div className="row">
              <form onSubmit={this.handleSignUp}>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <h2>Regístrate como donante</h2>
                  <br />
                  <div className="input-field">
                    <select
                      id='type_doc'
                      name='tipo_documento'
                      className="browser-default"
                      ref={(input) => { this.tipo_documento = input; }}
                      onChange={this.onChange}
                      value={this.state.tipo_documento}>
                      <option value="" disabled>Tipo de documento *</option>
                      <option value="cédula">Cédula de ciudadanía</option>
                      <option value="cédula de extranjeria">Cédula de extranjería</option>
                      <option value="pasaporte">Pasaporte</option>
                    </select>
                    {this.state.error_tipo_documento &&
                      <p className="txt-fucsia">{this.state.error_tipo_documento}</p>}
                  </div>
                </div>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <div className="input-field">
                    <input
                      id="num_doc"
                      placeholder=""
                      autoComplete="document"
                      type="number"
                      className="validate"
                      name='numero_documento'
                      ref={(input) => { this.numero_documento = input; }}
                      onChange={this.onChange}
                      value={this.state.numero_documento}
                    />
                    <label>Número de documento *</label>
                    {this.state.error_numero_documento &&
                      <p className="txt-fucsia">{this.state.error_numero_documento}</p>}
                  </div>
                </div>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <div className="input-field">
                    <input
                      placeholder="Primer nombre"
                      id="first_name"
                      autoComplete="name"
                      name='primer_nombre'
                      type="text"
                      className="validate"
                      ref={(input) => { this.primer_nombre = input; }}
                      onChange={this.onChange}
                      value={this.state.primer_nombre}
                    />
                    <label>Primer nombre *</label>
                    {this.state.error_primer_nombre &&
                      <p className="txt-fucsia">{this.state.error_primer_nombre}</p>}
                  </div>
                  <div className="input-field">
                    <input
                      placeholder="Segundo nombre"
                      id="second_name"
                      autoComplete="name"
                      name='segundo_nombre'
                      type="text"
                      className="validate"
                      onChange={this.onChange}
                      value={this.state.segundo_nombre}
                    />
                    <label>Segundo nombre</label>
                    {this.state.error_segundo_nombre &&
                      <p className="txt-fucsia">{this.state.error_segundo_nombre}</p>}
                  </div>
                  <div className="input-field">
                    <input
                      placeholder="Primer apellido"
                      id="last_name"
                      autoComplete="lastname"
                      name='primer_apellido'
                      type="text"
                      className="validate"
                      ref={(input) => { this.primer_apellido = input; }}
                      onChange={this.onChange}
                      value={this.state.primer_apellido}
                    />
                    <label>Primer apellido *</label>
                    {this.state.error_primer_apellido &&
                      <p className="txt-fucsia">{this.state.error_primer_apellido}</p>}
                  </div>
                  <div className="input-field">
                    <input
                      placeholder="Segundo apellido"
                      id="second_last_name"
                      autoComplete="lastname"
                      name='segundo_apellido'
                      type="text"
                      className="validate"
                      onChange={this.onChange}
                      value={this.state.segundo_apellido}
                    />
                    <label>Segundo apellido</label>
                    {this.state.error_segundo_apellido &&
                      <p className="txt-fucsia">{this.state.error_segundo_apellido}</p>}
                  </div>
                </div>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <div className="input-field col s6">
                    <input
                      placeholder="Edad"
                      id="age"
                      autoComplete="age"
                      name='edad'
                      type="number"
                      className="validate"
                      onChange={this.onChange}
                      value={this.state.edad}
                    />
                    <label>Edad</label>
                    {this.state.error_edad &&
                      <p className="txt-fucsia">{this.state.error_edad}</p>}
                  </div>
                  <div className="input-field col s6">
                    <select
                      id="sex"
                      name='sexo'
                      className="browser-default"
                      onChange={this.onChange}
                      value={this.state.sexo}>
                      <option value="" disabled>Género</option>
                      <option value="masculino">Masculino</option>
                      <option value="femenino">Femenino</option>
                    </select>
                    {this.state.error_sexo &&
                      <p className="txt-fucsia">{this.state.error_sexo}</p>}
                  </div>
                </div>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  {this.state.showProgressBar && <Preloader />}
                  {!this.state.showProgressBar &&
                    <div className="input-field">
                      <select
                        name='municipio_residencia'
                        className="browser-default"
                        ref={(input) => { this.municipio_residencia = input; }}
                        onChange={this.onChange}
                        value={this.state.municipio_residencia}>
                        <option value="" disabled>Municipio *</option>
                        {options}
                      </select>
                      {this.state.error_municipio_residencia &&
                        <p className="txt-fucsia">{this.state.error_municipio_residencia}</p>}
                    </div>
                  }
                </div>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <div className="input-field">
                    <input
                      placeholder=""
                      autoComplete="phone"
                      id="tel"
                      type="number"
                      className="validate"
                      name='numero_telefonico'
                      ref={(input) => { this.numero_telefonico = input; }}
                      onChange={this.onChange}
                      value={this.state.numero_telefonico} />
                    <label>Número telefónico *</label>
                    {this.state.error_numero_telefonico &&
                      <p className="txt-fucsia">{this.state.error_numero_telefonico}</p>}
                  </div>
                </div>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <div className="input-field">
                    <input
                      placeholder=""
                      id="email"
                      type="email"
                      autoComplete="email"
                      className="validate"
                      name='correo_electronico'
                      ref={(input) => { this.correo_electronico = input; }}
                      onChange={this.onChange}
                      value={this.state.correo_electronico} />
                    <label>Correo electrónico *</label>
                    {this.state.error_correo_electronico &&
                      <p className="txt-fucsia">{this.state.error_correo_electronico}</p>}
                  </div>
                </div>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  {this.state.showProgressBar2 && <Preloader />}
                  {!this.state.showProgressBar2 &&
                    <div className="input-field">
                      <select
                        id='lug_vol'
                        name='lugar_voluntariado'
                        className="browser-default"
                        ref={(input) => { this.lugar_voluntariado = input; }}
                        onChange={this.onChange}
                        value={this.state.lugar_voluntariado}>
                        <option value="" disabled>Lugar de voluntariado *</option>
                        {optionsPlaces}
                      </select>
                      {this.state.error_lugar_voluntariado &&
                        <p className="txt-fucsia">{this.state.error_lugar_voluntariado}</p>}
                    </div>
                  }
                </div>
                {this.state.showProgressBar3 && <Preloader />}
                {!this.state.showProgressBar3 &&
                  <div className="col s12 m10 offset-m1 l4 offset-l4">
                    <div className="input-field">
                      <this.FuncionDatepicker
                        id="fecha"
                        name="dias"
                        label="Dias *"
                        initialDay={this.state.currentPointDate}
                        activeDays={this.state.currentPointDays}
                        readOnly={this.state.readOnlyDate}
                      />
                      {this.state.error_dias &&
                        <p className="txt-fucsia">{this.state.error_dias}</p>}
                    </div>
                    <div className="input-field col s6">
                      <this.FuncionTimepickerMin
                        min={this.state.minInitialHour}
                        max={this.state.maxInitialHour}
                        value={this.onChange}
                        id="horario_inicio"
                        name="horario_inicio"
                        label="Horario inicial *"
                        readOnly={this.state.readOnlyTimeMin}
                      />
                      {this.state.error_horario_inicio &&
                        <p className="txt-fucsia">{this.state.error_horario_inicio}</p>}
                    </div>
                    <div className="input-field col s6">
                      <this.FuncionTimepickerMax
                        min={this.state.minFinalHour}
                        max={this.state.maxFinalHour}
                        value={this.onChange}
                        id="horario_fin"
                        name="horario_fin"
                        label="Horario final *"
                        readOnly={this.state.readOnlyTimeMax}
                      />
                      {this.state.error_horario_fin &&
                        <p className="txt-fucsia">{this.state.error_horario_fin}</p>}
                    </div>
                  </div>
                }
                {/* <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <div className="input-field">
                    <input
                      type="text"
                      className="datepicker"
                      placeholder=""
                      id="fecha"
                      name='dias'
                      onChange={this.onChange}
                      value={this.state.dias} />
                    <label>Dias *</label>
                    {this.state.error_dias && <p style={{ color: 'red' }}>{this.state.error_dias}</p>}
                  </div>
                </div>
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <div className="input-field">
                    <input type="text"
                      className="timepicker"
                      maxlength="8"
                      placeholder=""
                      id="horario_inicio"
                      name='horario_inicio'
                      onChange={this.onChange}
                      value={this.state.horario_inicio}/>
                    <label>Horario inicio*</label>
                    {this.state.error_horario_inicio && <p style={{ color: 'red' }}>{this.state.error_horario_inicio}</p>}
                  </div>
                  <div className="input-field">
                    <input type="text"
                      step="1"
                      placeholder=""
                      id="horario_fin"
                      name='horario_fin'
                      onChange={this.onChange}
                      value={this.state.horario_fin}
                      className="timepicker" />
                    <label>Horario fin *</label>
                    {this.state.error_horario_fin && <p style={{ color: 'red' }}>{this.state.error_horario_fin}</p>}
                  </div>
                </div> */}
                <div className="col s12 m10 offset-m1 l4 offset-l4">
                  <p style={{ color: 'black' }}>Obligatorio (*)</p>
                  {this.state.error &&
                    <p className="txt-fucsia">{this.state.error}</p>}
                  <br />
                  <button
                    type='submit'
                    className="waves-effect waves-light btn-large accbtn btn-text-overflow">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* <button onClick={() => this.setState({ show: true, sweetAlertTitle: "", sweetAlertText: "" })}>Alert</button> */}
          <SweetAlert
            type='success'
            show={this.state.show}
            title={this.state.sweetAlertTitle}
            text={this.state.sweetAlertText}
            onConfirm={() => {
              this.setState({
                show: false
              });
              if (this.state.sweetAlertRegister)
                setTimeout(this.redirectToTarget('/login'), 2000);
              if (this.state.sweetAlertUpdate)
                setTimeout(this.redirectToTarget('/dashboard'), 2000);
            }}
          />
          <div className="container pad-bot-pag">
            <div className="row">
              <div className="col s12 m10 offset-m1 l4 offset-l4">
                {/* <Link
                  to="/dashboard"
                  className="waves-effect waves-light btn-large accbtn"
                >
                  Enviar
                </Link> */}
                <button
                  className="waves-effect waves-teal btn-flat accbtn btn-text-overflow"
                  onClick={() => { this.redirectToTarget('/terminos') }}>
                  Ver términos de servicio
                </button>
                {/* <Link
                  to="/terminos"
                  className="waves-effect waves-teal btn-flat accbtn">
                  Ver términos de servicio
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer --> */}
        <footer className="page-footer footer-fixed" id="eatc-usr-fn">
          <div className="container-footer">
            <div className="row">
              <div className="col l4 s4">
                <button
                  className="waves-effect waves-light btn accbtn"
                  onClick={() => { this.redirectToTarget('/donacion_alimentos') }}>
                  <i className="material-icons top">shopping_basket</i>
                  <span>Donar Alimentos</span>
                </button>
                {/* <Link
                  to="/donacion_alimentos"
                  className="waves-effect waves-light btn accbtn">
                  <i className="material-icons top">shopping_basket</i>
                  <span>Donar Alimentos</span>
                </Link> */}
              </div>
              <div className="col l4 s4 center-align">
                <button
                  className="waves-effect waves-light btn accbtn"
                  onClick={() => { this.redirectToTarget('/voluntarios') }}>
                  <i className="material-icons top">favorite_border</i>
                  <span>Tiempo como voluntario</span>
                </button>
                {/* <Link
                  to="/voluntarios"
                  className="waves-effect waves-light btn accbtn">
                  <i className="material-icons top">favorite_border</i>
                  <span>Tiempo como voluntario</span>
                </Link> */}
              </div>
              <div className="col l4 s4">
                <button
                  className="waves-effect waves-light btn accbtn"
                  onClick={() => { this.redirectToTarget('/dinero') }}>
                  <i className="material-icons top">credit_card</i>
                  <span>Donar<br />dinero</span>
                </button>
                {/* <Link
                  to="/dinero"
                  className="waves-effect waves-light btn accbtn">
                  <i className="material-icons top">credit_card</i>
                  <span>Donar<br />dinero</span>
                </Link> */}
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default eatc_alim_vol;
