import React, { Component } from "react";
import { Link } from "react-router-dom";
//import { browserHistory } from 'react-router';
//import { withRouter } from 'react-router-dom';
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../css/custom.css"
import "../../App.css";
//import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
// Import Materialize
import M from "materialize-css";

export class eatc_alim_terms extends Component {
  componentDidMount() {
    M.AutoInit();
    //M.updateTextFields();
  }

  redirectToTargetBack = () => {
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  render() {
    const divStyle = {
      fontSize: "xx-large"
    };
    return (
      <div id="otros" className="pagina">
        <div className="navbar-fixed">
          <nav>
            <div className="nav-wrapper">
              <ul id="nav-mobile" className="left">
                <li>
                  {/* <button
                    className=""
                    onClick={this.redirectToTargetBack}>
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </button> */}
                  <Link to="#" className="accbtn" onClick={() => { this.redirectToTargetBack() }}>
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </Link>
                 {/*  <Link to="/login" className="accbtn">
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </Link> */}
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="container">
          <h1>TÉRMINOS DE USO Y AVISO DE PRIVACIDAD</h1>
          <h2>Términos de uso</h2>
          <p>Los presentes TÉRMINOS DE USO son requisito fundamental para el uso de
la APLICACIÓN “EatCloud” el sitio web eatcloud.com y los servicios ofrecidos
por ambas.</p>
          <h3>GENERALIDADES</h3>
          <p>El USUARIO es el único responsable por el uso que pueda dar a los servicios
  y las aplicaciones y por cualquier consecuencia que de ello se derive. Al
  instalar esta APLICACIÓN (tal como se define abajo), al registrarte en el
  servicio y/o utilizarlo de cualquier forma (incluyendo, entre otras cosas, visitar
  o navegar por el sitio, utilizar la APLICACIÓN), acepta todo lo pactado en los
  presentes TÉRMINOS DE USO los cuales deben leerse detenidamente.</p>
          <h3>DEFINICIONES</h3>
          <p>Por la APLICACIÓN “EatCloud” (en adelante la APLICACIÓN) se entiende el
software facilitado por EATCLOUD y sus licenciadores (ABACO Y
NODRIZZA NETWORK S.A.) para gestionar donaciones de alimentos,
donaciones en dinero o donaciones de tiempo.
Al aceptar estos términos y condiciones de uso, usted declara y acepta que la
donación gestionada a través de LA APLICACIÓN, entregado por usted como
persona natural o jurídica a título gratuito y por concepto de donación a la
Asociación Banco de Alimentos de Colombia – ABACO, está en los
documentos soportes relacionado a costo y antes de IVA, conforme lo indica
el artículo 125-2 del Estatuto Tributario.
Una vez cumplida y aceptada la entrega de producto en donación y que se
encuentre apto para consumo y/o uso, ABACO emitirá una certificación y/o
constancia donación, siempre y cuando se cumpla con los requisitos de ley,
para los beneficios reconocidos en el estatuto tributario para las donaciones.
ABACO, los Bancos de Alimentos de Colombia y sus aliados cuentan con la
logística requerida para la consecución, recepción, almacenamiento,
separación, clasificación, conservación y distribución de los alimentos
recibidos en donación, para lo cual se apoyan como fuente de financiación en
el aporte solidario.
LA APLICACIÓN puede ser utilizada en dispositivos con los sistemas
operativos Apple iOS y Android OS, así como sus actualizaciones periódicas
y cualquier otro software o documentación que permita utilizar esta
APLICACIÓN. También podrá ser accedido y consultado a través del sitio
web<a href="http://alimentaton.eatcloud.info"
              className=""
              target="_blank"
              rel="noopener noreferrer"> http://alimentaton.eatcloud.info </a>.</p>
          <h3>CAPACIDAD</h3>
          <p>Los servicios ofrecidos por LA APLICACIÓN podrán ser utilizados por
personas con plena capacidad de contratar y de ejercer derechos y asumir
obligaciones conforme a la ley; solamente se podrán utilizar los servicios
ofrecidos por LA APLICACIÓN si cuenta con esta capacidad y su
consentimiento carece de vicios. Los servicios que LA APLICACIÓN presta
evolucionan continuamente y, por ello, la forma o naturaleza de tales
servicios pueden cambiar de un momento a otro sin que se le haya notificado
con antelación tal cambio.<br /><br />LA APLICACIÓN puede interrumpir la prestación de los servicios o parte de
                                                                                                                                                                                                                                                                                                                                                                                                los mismos a usted o a todos los USUARIOS en general y, en estas
                                                                                                                                                                                                                                                                                                                                                                                                circunstancias, es posible que LA APLICACIÓN no pueda notificarle este
hecho con antelación.<br /><br />LA APLICACIÓN se reserva el derecho a poner límites al uso en cualquier
momento y sin previo aviso.</p>
          <h3>LOS SERVICIOS</h3>
          <p>LA APLICACIÓN es una plataforma ideada para la recepción y generación de
alertas de acuerdo a la ubicación del USUARIO. El USUARIO entrega
información de contacto, de terceras personas para invitarlos a descargar la
APLICACIÓN y formar parte de la cominidad de EATCLOUD. El sistema le
permitirá realizar donaciones de alimentos, donaciones en dinero o
donaciones de tiempo. El USUARIO podrá compartir su ubicación (con mutuo
consentimiento) y de esta manera podrá ver los puntos mas cercanos de
donación de alimentos.<br /><br />
            Recepción de mensajes: según la segmentación demográfica, psicográfica, o
            según la posición del USUARIO, el sistema podrá desplegar mensajes y
            acciones asociadas a campañas de donación. El USUARIO podrá dar
            testimonio de las acciones realizadas mediante sugerencia de la plataforma y
            ésta capturará la fecha y hora de las mismas y su ubicación, con el ánimo de
            ponerlas en un mapa. Algunas de estas acciones podrán implicar la toma de
fotografías o la subida de material multimedia.</p>
          <h3>PRIVACIDAD</h3>
          <p>Cualquier información que el USUARIO facilite a la APLICACIÓN está sujeta
a nuestras POLÍTICAS DE PRIVACIDAD Y TRATAMIENTO DE LA
INFORMACIÓN. Por la presente se le informa de que el uso de los Servicios
comporta su consentimiento implícito a la recogida y el uso de esta
información (conforme a lo dispuesto en nuestras POLÍTICAS DE
PRIVACIDAD Y TRATAMIENTO DE LA INFORMACIÓN, incluyendo,
asimismo, su consentimiento implícito a la transferencia de esta información a
Estados Unidos o a otros países para su almacenamiento conforme será
debidamente informado en las POLÍTICAS DE PRIVACIDAD Y
TRATAMIENTO DE LA INFORMACIÓN documento que hace parte integral

de este acuerdo. Como parte de la prestación de los servicios, es posible que
tengamos que notificarle determinada información como, por ejemplo, las
novedades en relación con la prestación de los servicios. Estas notificaciones
se consideran parte integrante de los Servicios y de su cuenta en
EATCLOUD.</p>
          <h3>PROPIEDAD INTELECTUAL</h3>
          <h3>LICENCIA Y DERECHOS DE PROPIEDAD</h3>
          <p>Todas las marcas, derechos de autor, bases de datos y otros derechos de
  propiedad intelectual de LA APLICACIÓN, cualquiera que sea su naturaleza,
  así como el código fuente, son propiedad de EATCLOUD o sus licenciadores
(ABACO Y NODRIZZA NETWORK S.A.)<br /><br />
            Por el presente, EATCLOUD. ofrece una licencia personal revocable, gratuita,
            no exclusiva, no transferible y de carácter internacional para utilizar esta
            APLICACIÓN y el sitio eatcloud.com en el ámbito personal o profesional
            dentro de los límites establecidos por estos términos de la APLICACIÓN.
            Esta licencia le permitirán disfrutar los servicios ofrecidos tanto por la
APLICACIÓN como por el sitio web conforme a estos TÉRMINOS DE USO.<br /><br />
            En cuanto a la información compartida como mensajes, fotos, videos, etc.,
            que el USUARIO comparta a través de la plataforma, el USUARIO concede
una licencia no exclusiva, transferible con derechos de sublicencia,<a href="https://creativecommons.org/licenses/by/3.0/legalcode"
              className=""
              target="_blank"
              rel="noopener noreferrer"> Creative
              Commons Attibution 3.0 Unported </a>(CC By 3.0) y con aplicabilidad global para
el adecuado funcionamiento de la plataforma y de la cuenta o cuentas del
USUARIO. Esta licencia se termina cuando el USUARIO elimina su contenido
de su cuenta, salvo que este contenido hubiera sido compartido con terceros
y éstos no lo hubiesen eliminado.</p>
          <h3>DERECHOS DE EATCLOUD</h3>
          <p>Los servicios son propiedad exclusiva de EATCLOUD y sus licenciadores
(ABACO Y NODRIZZA NETWORK S.A.). Los servicios están protegidos por
derechos de propiedad intelectual y de marca, así como por la normativa de
Colombia y de países terceros. Estas condiciones no le otorgan derecho
alguno en relación con el uso del nombre de “LA APLICACIÓN”, la marca
EATCLOUD, los logos, nombres de dominio o cualesquiera otras
características distintivas de marca.</p>
          <h3>CONDICIONES DE USO</h3>
          <p>El USUARIO no actuará ni permitirá que terceras personas actúen en su
nombre para:</p>
          <dl>
            <dd><ul><li type="disc" className="off-li">Hacer y distribuir copias de la APLICACIÓN;</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Intentar copiar, reproducir, alterar, modificar, hacer ingeniería inversa,
            desarmar, descompilar, transferir, intercambiar o traducir la
APLICACIÓN;</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Crear ninguna clase de proyecto derivado de la APLICACIÓN.</li></ul></dd>
          </dl>
          <p>La APLICACIÓN se entrega al USUARIO de manera gratuita para su uso
        personal y no comercial. EATCLOUD se reserva el derecho a modificar o
        deshabilitar la APLICACIÓN en cualquier momento y por cualquier motivo,
        así como a aplicar recargos económicos por la APLICACIÓN o el servicio
facilitado al USUARIO en conformidad con los presentes términos.<br /><br />El USUARIO acepta que los términos del acuerdo con el proveedor de su red
                                                                                                                                                                                                                                                                        móvil (PROVEEDOR DE SERVICIOS MÓVILES) continuarán en vigor
                                                                                                                                                                                                                                                                        durante el uso de la APLICACIÓN. En consecuencia, es posible que el
                                                                                                                                                                                                                                                                        PROVEEDOR DE SERVICIOS MÓVILES cobre por los servicios de conexión
                                                                                                                                                                                                                                                                        a la red mientras la APLICACIÓN permanezca conectada a la red, o que se
                                                                                                                                                                                                                                                                        produzcan cargos económicos de terceros. El USUARIO acepta la
responsabilidad de los cargos que pudieran surgir.<br /><br />Si el USUARIO no es el titular de facturación del teléfono o dispositivo móvil
                                                                                                                                                                                                                                                            utilizado para acceder a la APLICACIÓN, se asumirá que tiene permiso del
titular para utilizar la APLICACIÓN.</p>
          <h3>CONDUCTAS PROHIBIDAS</h3>
          <p>Los USUARIOS deberán guardar un código de conducta al comprometerse a
<b> NO</b> difundir información del siguiente tipo:</p>
          <dl>
            <dd><ul><li type="disc" className="off-li">Falsa o que induzca al error.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Material pornográfico de cualquier índole.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Información que induzca al odio o a la violencia.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Información que induzca a la discriminación de minorías.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Información que viole la ley y las buenas costumbres sociales.</li></ul></dd>
          </dl>
          <p>
            Estas conductas prohibidas no son taxativas sino enunciativas y no se limitan
  a lo establecido en las mismas sino a cualquier conducta que pudiera violar la
  ley o fuere conforme a discreción de EATCLOUD violatorias del presente
  acuerdo entre las partes.
          </p>
          <h3>DISPONIBILIDAD</h3>
          <p>Esta APLICACIÓN está disponible para los dispositivos móviles portátiles que
funcionen con los sistemas operativos Apple iOS y Android OS.<br /><br />
            EATCLOUD y sus licenciadores (ABACO Y NODRIZZA NETWORK S.A.)

            harán todos los esfuerzos razonables para que la APLICACIÓN esté
            disponible en todo momento. No obstante, el USUARIO acepta que la
            entrega de la APLICACIÓN se realizará a través de Internet y de las redes
            móviles y que, en consecuencia, la calidad y disponibilidad de la
            APLICACIÓN pueden verse afectadas por factores ajenos al control
            responsable de EATCLOUD y sus licenciadores (ABACO Y NODRIZZA
NETWORK S.A.)<br /><br />
            EATCLOUD, sus licenciadores (ABACO Y NODRIZZA NETWORK S.A.) y
            sus subcontratistas no se hacen responsables de la no disponibilidad de la
            APLICACIÓN, de las dificultades o de la imposibilidad de descargar o
            acceder a su contenido, ni de ningún otro fallo en el sistema de comunicación
que pudiera provocar que la APLICACIÓN no esté disponible.<br /><br />
            EATCLOUD y sus licenciadores (ABACO Y NODRIZZA NETWORK S.A.)
            no se harán responsable del servicio técnico ni del mantenimiento de la
APLICACIÓN.</p>
          <h3>FALLAS EN EL SISTEMA</h3>
          <p>EATCLOUD y sus licenciadores (ABACO Y NODRIZZA NETWORK S.A.)
no se responsabilizan por cualquier daño, perjuicio o pérdida de
equipamiento del USUARIO causada por fallas en el sistema, el servidor o en
Internet. Tampoco será responsable por cualquier virus que pueda atacar el
equipo del USUARIO como consecuencia del acceso, utilización o
navegación en el sitio en Internet o como consecuencia de la transferencia de
datos, archivos, imágenes, textos o audio contenidos en el SITIO WEB. Los
USUARIOS no podrán atribuir a EATCLOUD o sus licenciadores (ABACO Y
NODRIZZA NETWORK S.A.) ninguna responsabilidad ni exigir el pago por
lucro cesante o cualquier tipo de indemnización en virtud de perjuicios o
responsabilidad civil resultantes de dificultades técnicas o fallas en los
sistemas o en Internet. EATCLOUD o sus licenciadores (ABACO Y
NODRIZZA NETWORK S.A.) tampoco puede garantizar el acceso o el
funcionamiento sin interrupciones de su sitio. Eventualmente, el sistema
podrá no estar disponible por motivos técnicos o fallas de Internet.</p>
          <h3>REQUISITOS DEL SISTEMA</h3>
          <p>Para poder utilizar la APLICACIÓN, el USUARIO debe tener un teléfono o
  dispositivo móvil compatible, con conexión a Internet y que cumpla las
  especificaciones mínimas según se estipula en las respectivas tiendas de
aplicaciones.<br /><br />
            Las APLIACIONES de EATCLOUD serán compatibles con dispositivos
            Android OS con los sistemas operativos Android 5.0 en adelante y en algunas
            ocaciones con dispositivos Apple iOS. Por favor consulte en la tienda de
            aplicaciones la compatibilidad de LA APLICACIÓN con el sistema operativo
de su dispositivo móvil.<br /><br />
            La versión del software de la APLICACIÓN puede sufrir actualizaciones
periódicas para añadir compatibilidad con nuevas funciones y servicios.</p>
          <h3>FINALIZACIÓN</h3>
          <p>EATCLOUD puede finalizar el uso de la APLICACIÓN en cualquier momento
sin que medie notificación al USUARIO.<br /><br />
            Al finalizar dicho uso también se pondrá fin a todos los derechos y licencias
otorgados al USUARIO; el USUARIO deberá dejar de utilizar el software.</p>
          <h3>LIMITACIÓN DE RESPONSABILIDADES</h3>
          <p>En ningún caso se podrá hacer responsable a EATCLOUD o sus
licenciadores (ABACO Y NODRIZZA NETWORK S.A.) de ningún tipo de
pérdidas o daños directos, indirectos, especiales, punitivos, ejemplares o
consecuentes, derivados del uso o el acceso a la APLICACIÓN, incluida la
pérdida de beneficios y similares, se deban o no a la falta de cumplimiento
por parte de las partes, ya sea por infracción del contrato, agravio (incluida
negligencia), responsabilidad del producto u otros.<br /><br />
            EATCLOUD y sus licenciadores (ABACO Y NODRIZZA NETWORK S.A.)
            no se harán responsable de ningún daño o alteración en los equipos del
            USUARIO como resultado de la instalación o el uso de la APLICACIÓN,
            incluyendo, pero sin limitarse a, equipos informáticos, dispositivos portátiles o
teléfonos móviles.</p>
          <h3>EXENCIÓN DE RESPONSABILIDAD PARA LAS GARANTÍAS</h3>
          <p>Con el fin de evitar dudas y de acuerdo con lo máximo permitido por la ley,
EATCLOUD y sus licenciadores (ABACO Y NODRIZZA NETWORK S.A.)
se eximen de cualquier responsabilidad en relación a todas las garantías
implícitas derivadas de la APLICACIÓN. La APLICACIÓN y el software se
suministran «tal cual» y «tal como están disponibles» sin ningún tipo de
garantía.</p>
          <h3>JURISDICCIÓN</h3>
          <p>Este acuerdo se guiará por las leyes vigentes en la República de
Colombia.<br /><br />
            Cualquier controversia derivada del presente acuerdo, será sometida a las
            Leyes aplicables y a los Tribunales competentes de la Ciudad de Medellín
y los procedimientos se llevarán a cabo en idioma castellano.</p>
          <h3>DOMICILIO</h3>
          <p>Se fija como domicilio de EATCLOUD y/o NODRIZZA NETWORK S.A. en
Medellín en la Calle 45D # 76ª – 03.</p>
          <h2>POLÍTICA DE PRIVACIDAD Y TRATAMIENTO DE LA INFORMACIÓN
(AVISO DE PRIVACIDAD)</h2>
          <p>Las presentes POLÍTICAS DE PRIVACIDAD Y TRATAMIENTO DE LA
  INFORMACIÓN han sido redactadas con el fin de poner en conocimiento del
  USUARIO que información se recaba por medio de la utilización de la
  APLICACIÓN LA APLICACIÓN y el sitio web eatcloud.com cómo la usamos,
con quién los compartimos y sus opciones con respecto a sus datos.<br /><br />
            El USUARIO nos entrega ciertos datos que son considerados personales por
            la legislación Colombiana conforme a la ley 1581 de 2012. Los datos
            recabados serán tratados directamente por EATCLOUD a través de su
            licenciatario NODRIZZA NETWORK S.A., empresa identificada con el NIT
            811.029.245-6 y domiciliada en Medellín en la Calle 45D # 76ª - 03
            Departamento de Antioquia, República de Colombia, quien a su vez será
            encargada y responsable del tratamiento, aplicando las disposiciones legales
vigentes.<br /><br />
            Los datos aportados cuentan con los estándares de seguridad exigidos por la
            Ley y serán guardados en LA BASE DE DATOS DE USUARIOS EATCLOUD
            en un servidor dedicado proveído por la empresa Godaddy en los Estados
            Unidos de Norteamérica, la cual tiene como finalidad el correcto
            funcionamiento de los aplicativos base de la presente política y de los demás
aplicativos EATCLOUD.<br /><br />
            Se realizará un trato de los datos suministrados por los USUARIOS conforme
            a la Ley, el cual incluye la absoluta reserva sobre estos datos y la no
            divulgación de los mismos. La información recolectada que pone de
            manifiesto los procesos de donación a nivel país, uno de los objetivos de esta
            APLICACIÓN, será compartida en un mapa de calor y podrá ser compartida
            con terceros a partir de la filosofía de DATOS ABIERTOS (OPEN DATA). Nos
            reservamos el derecho de reporte de información una vez en conocimiento de
            alguna transgresión a la ley vigente en la República de Colombia. Este aviso
            será considerado para todos los efectos legales como un consentimiento
informado.<br /><br />
            EATCLOUD puede llegar a recoger la siguiente información personal, a
través de la APLICACIÓN:</p>
          <dl>
            <dd><ul><li type="disc" className="off-li">Nombre, correo electrónico, teléfono celular, nombre de USUARIO, contraseña: con el fin de identificar los USUARIOS en la plataforma y
establecer canales de comunicación con los mismos.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Datos de acceso a redes sociales: Facebook, Twitter, Instagram,
Google+ (a través de protocolos de autenticación como OAuth): Con el
fin de realizar procesos de interacción y para compartir información en
estos medios sociales.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Datos demográficos: edad, sexo, etc. Lo anterior con el ánimo de
dirigir mensajes que promuevan acciones de protección. También se
utilizará esta información para realizar campañas de índole comercial
no intrusiva.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Datos de segmentación psicográfica: ocupación, hobbies, etc. Lo
anterior con el ánimo de dirigir mensajes que promuevan acciones de
protección. También se utilizará esta información para realizar
campañas de índole comercial no intrusiva.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Datos de ubicación y timestamp asociados a la realización de reportes
de donaciones. Esta información es de carácter público y su finalidad
es la de realizar mapas de calor. Esta información podrá ser parte de
nuestro modelo de monetización, es decir, ser vendida a terceros, con
la debida protección de la privacidad del USUARIO conforme a la Ley.
También podrá ser compartida mediante la filosofía de DATOS
ABIERTOS con terceros y autoridades competentes.</li></ul></dd>
          </dl>
          <p>Las medidas de seguridad aplicadas a la BASE DE DATOS DE USUARIOS
EATCLOUD incluyen:</p>
          <dl>
            <dd><ul><li type="disc" className="off-li">Protección del lado del servidor contra ataques informáticos mal
intencionados.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">Respaldo periódico de los datos.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">El acceso remoto a la base de datos está restringido.</li></ul></dd>
            <dd><ul><li type="disc" className="off-li">El acceso a la base de datos está protegido con nombres de usuario y
contraseñas seguras (nivel de complejidad alto).</li></ul></dd>
          </dl>
          <h2>Cambios a la política de privacidad</h2>
          <p>EATCLOUD podrá modificar esta POLÍTICA DE PRIVACIDAD Y
TRATAMIENTO DE LA INFORMACIÓN de vez en cuando. El uso de la
información que recogemos ahora está sujeto a la POLÍTICA DE
PRIVACIDAD Y TRATAMIENTO DE LA INFORMACIÓN en vigor en el
momento de utilizar esa información. Si hacemos cambios materiales en la
forma en que usamos la información personal, se lo notificaremos al
USUARIO publicando un aviso en nuestro servicio, en la respectiva tienda de
aplicaciones o enviándote un correo electrónico. Los USUARIOS están
obligados por los cambios en la POLÍTICA DE PRIVACIDAD al usar el
servicio después de que dichos cambios hayan sido publicados.</p>
          <h2>Reclamos o preguntas</h2>
          <p>Cualquier tipo de PQR (preguntas, quejas o reclamos) relacionado con la
información se podrá formular A NUESTRA UNIDAD DE SOPORTE
TÉCNICO en la siguiente dirección de correo electrónico:
<a href="mailto:soporte@nodrizza.com"
              className=""
              target="_blank"
              rel="noopener noreferrer"> soporte@nodrizza.com </a>.

Esta política entra en vigencia a partir de la entrada en vigor de la ley 1582 de
2012 y la vigencia de la base de datos a la cual hace referencia será de 50
años contada a partir del 1 de enero de 2013, prorrogables según sea
informado a los usuarios.</p>
        </div>
      </div>
    );
  }
}

export default eatc_alim_terms;
