import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../../css/design_system_styles.css";
import "../../../css/eatcloud.css";
import "../../../css/custom.css"
import "../../../App.css";
import puntodedonacion from "../../../img/punto de donacion@3x.png";
import { setLocation, isCitied, setCitied, getCitied, remCitied, isGeoLocated, remLocation } from "../../../services/auth";
import CityService from "../../../services/CityService";
import Preloader from '../../preloader/Preloader';
// Import Materialize
import M from "materialize-css";

export class eatc_alim_bienv2 extends Component {
  constructor(props) {
    super(props);  
    this.state = {
      latitude: "",
      longitude: "",
      showProgressBar: false,
    };
  }

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    console.log("...ESTAS EN /bienvenida2");
  }

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  goBack = () => window.history.back();

  redirectToTargetBack = (e) => {
    //e.preventDefault();
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  redirectToTargetLatLng = (lat, lng) => {
    console.log("redirect", lat, lng);
    this.props.history.push({
      pathname: "/bienvenida4", state: {
        lat: lat, lng: lng
      }
    });
  };

  handleClick = () => {
    this.getMyLocation();
  }

  getMyLocation = () => {
    console.log("...OBTENIENDO GEOLOCALIZACION (ASINCRONO)");
    this.setState({
      showProgressBar: !this.state.showProgressBar
    });
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        position => {
          console.log("@...GEOLOCALIZACION OBTENIDA (EXITO)");
          console.log("@...GUARDO EN EL STATE LATITUD-LONGITUD");
          this.getLocationCity(position.coords.latitude, position.coords.longitude);
          this.setState({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
          console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY LATITUD-LONGITUD");
          if (isGeoLocated()) {
            console.log("...SI HAY LATITUD-LONGITUD EN EL LOCALSTORAGE BORRO");
            remLocation();
            console.log("...AGREGO NUEVA LATITUD-LONGITUD EN EL LOCALSTORAGE");
            setLocation(position.coords.latitude, position.coords.longitude);
          } else {
            console.log("...SI NO HAY LATITUD-LONGITUD EN EL LOCALSTORAGE AGREGO");
            setLocation(position.coords.latitude, position.coords.longitude);
          }
          //Si quiero pasar la latitud y longitud por history
          //this.redirectToTargetLatLng(this.state.latitude, this.state.longitude);
        },
        error => {
          console.log("@...GEOLOCALIZACION NO OBTENIDA (ERROR)");
          console.log("@...GUARDO EN EL STATE LATITUD(ERROR)-LONGITUD(ERROR)");
          this.setState({
            latitude: "err-latitude",
            longitude: "err-longitude"
          });

          console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY LATITUD-LONGITUD");
          if (isGeoLocated()) {
            console.log("...SI HAY LATITUD-LONGITUD EN EL LOCALSTORAGE BORRO");
            remLocation();
            console.log("...AGREGO NUEVA LATITUD-LONGITUD(ERROR) EN EL LOCALSTORAGE");
            setLocation('err-latitude', 'err-longitude');
          } else {
            console.log("...SI NO HAY LATITUD-LONGITUD(ERROR) EN EL LOCALSTORAGE AGREGO");
            setLocation('err-latitude', 'err-longitude');
          }
          this.redirectToTarget('/bienvenida4');
          //Si quiero pasar la latitud y longitud por history
          //this.redirectToTargetLatLng(this.state.latitude, this.state.longitude);
        }
      );
    }
  }

  getLocationCity = (lat, lng) => {
    console.log('...GET CITY BY LATITUD (', lat, ') Y LONGITUD (', lng, ') (ASINCRONO)');
    CityService.getCity(lat, lng)
      .then(city => {
        let cityAccent = "";
        (city === 'Medellin') ? cityAccent = 'Medellín' : cityAccent = city
        console.log("@...CITY OBTENIDA (EXITO) -> ", cityAccent, '\nTIPO', typeof (cityAccent));
        this.setState({
          showProgressBar: !this.state.showProgressBar
        });
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY CITY");
        if (isCitied()) {
          console.log("...SI HAY CITY EN EL LOCALSTORAGE BORRO");
          remCitied();
          console.log("...AGREGO NUEVA CITY EN EL LOCALSTORAGE");
          setCitied(cityAccent);
        } else {
          console.log("...SI NO HAY CITY EN EL LOCALSTORAGE AGREGO");
          setCitied(cityAccent);
        }
        console.log('...CITY EN EL LOCALSTORAGE', getCitied(), '\nTIPO', typeof (getCitied()));
        this.redirectToTarget('/bienvenida4');
      })
      .catch(err => {
        console.log("@...CITY (ERROR) -> ", err);
        this.setState({
          showProgressBar: !this.state.showProgressBar
        });
        this.redirectToTarget('/bienvenida4');
      });
  }

  render() {
    const divStyle = {
      fontSize: "xx-large"
    };

    return (
      <div id="bienvenida2" className="pagina">
        <div className="navbar-fixed">
          <nav>
            <div className="nav-wrapper">
              <ul id="nav-mobile" className="left">
                <li>
                  <button
                    className="backButton accbtn"
                    onClick={() => { this.redirectToTargetBack() }}>
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </button>
                  {/* <Link to="#" className="accbtn" onClick={() => {this.redirectToTargetBack()}}>
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </Link> */}
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l6 offset-l3">
              <img
                className="responsive-img" //className margin-illustration-top
                src={puntodedonacion}
                alt="puntodedonacion"
              />
            </div>
          </div>
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
              <h1 className="center-align">
                Hay un punto de donación cerca a ti
              </h1>
              <h2 className="center-align">Necesitamos tu ubicación</h2>
              <h3 className="center-align">
                Compártenos tu ubicación y te mostraremos los puntos más
                cercanos de donación y voluntariado
              </h3>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
                {this.state.showProgressBar && <Preloader />}
                {!this.state.showProgressBar &&
                  <button
                    className="waves-effect waves-light btn-large accbtn btn-text-overflow"
                    onClick={() => { this.getMyLocation() }}>
                    Comparte tu ubicación
                </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default eatc_alim_bienv2;
