import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../../css/design_system_styles.css";
import "../../../css/eatcloud.css";
import "../../../css/custom.css"
import "../../../App.css";
import desayuno from '../../../img/desayuno@3x.png';
// Import Materialize
import M from "materialize-css";
import { isPoints, remPoints, isUser, remUser, isGeoLocated, remLocation, remAll} from "../../../services/auth";

export class eatc_alim_bienv1 extends Component {
  
  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    console.log("...ESTAS EN /bienvenida1");
  }

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ",target);
    this.props.history.push(target);
  };

  removeLocalStorage = () => {
    console.log("...REMOVER LOCALSTORAGE()");
    if(isUser()){
      console.log("...REMUEVE USUARIO");
      remUser();
    }
    if(isPoints()){
      console.log("...REMUEVE PUNTOS");
      remPoints();
    }
    if(isGeoLocated()){
      console.log("...REMUEVE GEOLOCALIZACION");
      remLocation();
    }
    remAll();
    this.redirectToTarget('/bienvenida2');
  }

  render() {
    return (
      <div id="bienvenida1" className="pagina">
        <div className="container">
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l6 offset-l3">
              <img src={desayuno} 
                className="responsive-img margin-illustration-top" alt="desayuno"
              />
            </div>
          </div>
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
              <h1 className="center-align">¡Bienvenidos a la Alimentatón 2019!</h1>
              <h3 className="center-align">
                Una iniciativa de la Asociación de Bancos de Alimentos de
                Colombia - ABACO
              </h3>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
                <p className="center-align">
                  La Alimentatón es apoyada por EATCLOUD
                </p>
                <button
                  className="waves-effect waves-light btn-large accbtn btn-text-overflow"
                  onClick={() => {this.redirectToTarget('/bienvenida2')}}>
                  ¡Vamos!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default eatc_alim_bienv1;
