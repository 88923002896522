import React from 'react';
import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import Splashscreen from "./components/splashscreen/Splashscreen";
import eatc_alim_bienv1 from "./components/bienvenida/1/eatc_alim_bienv1";
import eatc_alim_bienv2 from "./components/bienvenida/2/eatc_alim_bienv2";
import eatc_alim_bienv3 from "./components/bienvenida/3/eatc_alim_bienv3";
import eatc_alim_bienv4 from "./components/bienvenida/4/eatc_alim_bienv4";
import eatc_alim_dsh from "./components/dashboard/eatc_alim_dsh";
import eatc_alim_vol from "./components/voluntarios/eatc_alim_vol";
import eatc_alim_dona_mon from "./components/dinero/eatc_alim_dona_mon";
import eatc_alim_terms from "./components/terminos/eatc_alim_terms";
import eatc_alim_aut from "./components/login/eatc_alim_aut";
import eatc_alim_dona_cod from "./components/codigo/eatc_alim_dona_cod";
import not_found from "./components/notfound/not_found";
import eatc_alim_dona from "./components/donacion_alimentos/eatc_alim_dona";
import eatc_alim_reg from "./components/login/eatc_alim_reg";
const history = createBrowserHistory();

function fireTracking() {
    const trackingId = "UA-151788197-1"; // Replace with your Google Analytics tracking ID
    //ReactGA.initialize('UA-151836456-1'); //Unique Google Analytics tracking number //UA-151788197-1
    ReactGA.initialize(trackingId); //Unique Google Analytics tracking number //UA-151788197-1
    console.log("...CONSTRUCTOR ReactGA.initialize(", trackingId, ")");
    //ReactGA.pageview(window.location.pathname + window.location.search);
    //ReactGA.pageview(window.location.pathname + window.location.search);
    //console.log("...CONSTRUCTOR ReactGA.pageview(", window.location.pathname, " + ", window.location.search, " ) ");
    // Initialize google analytics page view tracking
    history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
    console.log("...CONSTRUCTOR history", history);
}

function Application() {
    fireTracking();
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/" component={eatc_alim_bienv1} />
                <Route path='/splashscreen' component={Splashscreen} />
                <Route path='/bienvenida1' component={eatc_alim_bienv1} />
                <Route path='/bienvenida2' component={eatc_alim_bienv2} />
                <Route path='/bienvenida3' component={eatc_alim_bienv3} />
                <Route path='/bienvenida4' component={eatc_alim_bienv4} />
                <Route path='/dashboard' component={eatc_alim_dsh} />
                <Route path='/donacion_alimentos' component={eatc_alim_dona} />
                <Route path='/voluntarios' component={eatc_alim_vol} />
                <Route path='/dinero' component={eatc_alim_dona_mon} />
                <Route path='/terminos' component={eatc_alim_terms} />
                <Route path='/login' component={eatc_alim_aut} />
                <Route path='/registro' component={eatc_alim_reg} />
                <Route path='/codigo' component={eatc_alim_dona_cod} />
                <Route path='*' component={not_found} />
            </Switch>
        </Router>
    )
}

export default Application;
