import API from "./config";

const DONATIONS_ENDPOINTS = {
  ALL_POINTS: "eatc_pods?eatc-id=_*", //URI para llamar a todas las peliculas
  CITY_POINTS: "eatc_pods?eatc-city=" //URI para llamar a una pelicula en especifico.
};

const PointDonationService = {
  //Objeto Movie Service

  getAll: () =>
    new Promise((resolve, reject) => {
      API.get(DONATIONS_ENDPOINTS.ALL_POINTS)
        .then(response => {
          console.log("$...RESPONSE.DATA -> ",response.data);
          console.log("$...RESPONSE.STATUS -> ",response.status);
          console.log("$...RESPONSE.STATUSTEXT -> ",response.statusText);
          return response.data.res
        })
        //.then(res => res.data.res)
        .then(
          //res => resolve(res)
          res => {
            resolve(res);
            console.log("$...RES -> ",res);
            console.log("$...RES.FIRST_POSITION -> ",res[0]);
          })
        .catch(err => {
            console.log("$...ERROR -> ",err);
            reject(err);
          });
    }), // Funcion que nos devolverá el valor de todas las peliculas
  getCity: (city) =>
    new Promise((resolve, reject) => {
      API.get(DONATIONS_ENDPOINTS.CITY_POINTS + city)
      .then(
        //res =>res.data.res
        response => {
          console.log("$...RESPONSE.DATA -> ",response.data);
          console.log("$...RESPONSE.STATUS -> ",response.status);
          console.log("$...RESPONSE.STATUSTEXT -> ",response.statusText);
          return response.data.res
        }
      )
      .then(res => {
          resolve(res);
          console.log("$...RES -> ",res);
          console.log("$...RES.FIRST_POSITION -> ",res[0]);
        }
      )
      .catch(err => {
        console.log("$...ERROR -> ",err);
        reject(err);
      });
    }) //Funcion que nos devolverá el valor de una pelicula, la cual buscaremos via ID.
};

export default PointDonationService;
