//export const CORREO_ELECTRONICO = "juan@eatcloud.com";
//export const NUMERO_CEDULA = "8161174";
export const CIUDAD_LOCATION = "CIUDAD_LOCATION";
export const isCitied = () => {
  console.log("#...SI TIENE CITY?");
  return (localStorage.getItem(CIUDAD_LOCATION) !== null);
}
export const getCitied = () => localStorage.getItem(CIUDAD_LOCATION);
export const setCitied = city => {
  console.log("#...GUARDANDO CITY : ", city);
  localStorage.setItem(CIUDAD_LOCATION, String(city));
}
export const remCitied = () => {
  console.log("#...ELIMINANDO CITY");
  localStorage.removeItem(CIUDAD_LOCATION);
}

export const INFO_USER = "INFO_USER";
export const isUser = () => localStorage.getItem(INFO_USER) !== null;
export const getUser = () => localStorage.getItem(INFO_USER);
export const setUser = info => localStorage.setItem(INFO_USER, JSON.stringify(info));
export const remUser = () => localStorage.removeItem(INFO_USER);


export const POINTS_CITY = "POINTS_CITY";
export const isPoints = () => localStorage.getItem(POINTS_CITY) !== null;
export const getPoints = () => localStorage.getItem(POINTS_CITY);
export const setPoints = points => {
  console.log("#...GUARDANDO POINTS : ", points);
  localStorage.setItem(POINTS_CITY, JSON.stringify(points));
}
export const remPoints = () => localStorage.removeItem(POINTS_CITY);

export const GEO_LOCATION = "GEO_LOCATION";
export const GEO_LOCATION_LAT = GEO_LOCATION+"_LAT";
export const GEO_LOCATION_LNG = GEO_LOCATION+"_LNG";
export const isGeoLocated = () => localStorage.getItem(GEO_LOCATION_LAT) !== null ||
localStorage.getItem(GEO_LOCATION_LNG) !== null;
export const getLat = () => localStorage.getItem(GEO_LOCATION_LAT);
export const getLng = () => localStorage.getItem(GEO_LOCATION_LNG);
export const setLocation = (lat, lng) => {
  console.log("#...GUARDANDO \nLATITUD : ", lat,"\nLONGITUD : ", lng);
  localStorage.setItem(GEO_LOCATION_LAT, lat);
  localStorage.setItem(GEO_LOCATION_LNG, lng);
}
export const remLocation = () => {
  localStorage.removeItem(GEO_LOCATION_LAT);
  localStorage.removeItem(GEO_LOCATION_LNG);
}

export const remAll = () => {
  console.log("#...ELIMINANDO TODA LA INFORMACION GUARDADA");
  localStorage.removeItem(GEO_LOCATION_LAT);
  localStorage.removeItem(GEO_LOCATION_LNG);
  localStorage.removeItem(POINTS_CITY);
  localStorage.removeItem(INFO_USER);
  localStorage.removeItem(CIUDAD_LOCATION);
}
