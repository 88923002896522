import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../css/custom.css"
import "../../App.css";
//import codigodedonacion from "../../img/codigo de donacion@3x.png";
// Import Materialize
//import M from "materialize-css";

export class not_found extends Component {
  render() {
    return <div className="not-found back-structure"></div>;
  }
}

export default not_found;
