import axios from 'axios';

//const BASE_URL = "https://geocode.xyz/6.2560936,-75.5901797?json=1";
const BASE_URL = "https://geocode.xyz/";
const API = axios.create({
    baseURL: BASE_URL,
})

const CITY_ENDPOINTS = {
    FINALLY: "?json=1", //URI para llamar a todos los campos de voluntarios.
    COMA: ","
};

const CityService = {
    getCity: (lat, lng) =>
      new Promise((resolve, reject) => {
        API.get(lat+CITY_ENDPOINTS.COMA+lng+CITY_ENDPOINTS.FINALLY)
          .then(
            res => {
              console.log("$...RES.DATA ->",res.data);
              console.log("$...RES.STATUS ->",res.status);
              console.log("$...RES.STATUSTEXT ->",res.statusText);
              return res.data.city
            }
          )
          .then(data => {
            resolve(data);
            console.log("$...DATA ->",data);
            console.log("$...DATA.FIRST_POSITION ->",data[0]);
          },
          )
          .catch(
              err => {
                console.log("$...ERROR ->",err);
                reject(err);
              });
      }), 
  };
  
  export default CityService;