//https://donantes.eatcloud.info/api/data/municipios_colombia?nombre_municipio=_*
//eatc_volunteers?correo_electronico=juan@eatcloud.com&numero_documento=8161174 
import axios from 'axios';

const BASE_URL = "https://donantes.eatcloud.info/api/data/";
const API  = axios.create({
    baseURL : BASE_URL,
})

/*API.interceptors.request.use(async config => {
  //const token = getToken();
  //if (token) {
    //config.headers.Authorization = `Bearer ${token}`;
  //}
  config.headers.Authorization = "Basic " + btoa( "nzzn:1nzzn_2018" );
  return config;
});*/

const HELPS_ENDPOINTS = {
  ALL_MUNICIPIOS: "municipios_colombia?nombre_municipio=_*", //URI para llamar a todos los campos de voluntarios.
};

/*var session_url = 'https://donantes.eatcloud.info/api/data/municipios_colombia?nombre_municipio=_*';
var uname = 'nzzn';
var pass = '1nzzn_2018';
axios.post(session_url, {}, {
  auth: {
    username: uname,
    password: pass
  }
}).then(function(response) {
  console.log('Authenticated');
}).catch(function(error) {
  console.log('Error on Authentication');
});*/

const HelpService = {
  getAllMunicipios: () =>
    new Promise((resolve, reject) => {
      API.get(HELPS_ENDPOINTS.ALL_MUNICIPIOS)
        .then(
          res => {
            console.log("$...RES.DATA -> ",res.data);
            console.log("$...RES.STATUS -> ",res.status);
            console.log("$...RES.STATUSTEXT -> ",res.statusText);
            return res.data.res
          }
        )
        .then(data => {
          resolve(data);
          console.log("$...DATA -> ",data);
          console.log("$...DATA.FIRST_POSITION -> ",data[0]);
        },
        )
        .catch(
            err => {
              console.log("$...ERROR -> ",err);
              reject(err);
            });
    }), 
};

export default HelpService;
