import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../../css/design_system_styles.css";
import "../../../css/eatcloud.css";
import "../../../css/custom.css"
import "../../../App.css";
import notificacion from "../../../img/notific@3x.png";
// Import Materialize
import M from "materialize-css";

export class eatc_alim_bienv3 extends Component {

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    console.log("...ESTAS EN /bienvenida3");
  }

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A,", target);
    this.props.history.push(target);
  };

  redirectToTargetBack = (e) => {
    //e.preventDefault();
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  render() {
    const divStyle = {
      fontSize: "xx-large"
    };

    return (
      <div id="bienvenida3" className="pagina">
        <div className="navbar-fixed">
          <nav>
            <div className="nav-wrapper">
              <ul id="nav-mobile" className="left">
                <li>
                  <button
                    className="backButton accbtn"
                    onClick={() => { this.redirectToTargetBack() }}>
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </button>
                  {/* <Link to="#" className="accbtn" onClick={() => { this.redirectToTargetBack() }}>
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </Link> */}
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l6 offset-l3">
              <img
                className="responsive-img" //className margin-illustration-top
                src={notificacion}
                alt="notificacion"
              />
            </div>
          </div>
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
              <h1 className="center-align">Síguenos</h1>
              <h2 className="center-align">
                Entérate de los avances de la ALIMENTATÓN
              </h2>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
                <p className="small opacity-54">
                  Las notificaciones se pueden ajustar o desactivar más tarde
                </p>
                <button
                  className="waves-effect waves-light btn-large accbtn btn-text-overflow"
                  onClick={() => { this.redirectToTarget('/bienvenida4') }}>
                  Activar notificaciones
                </button>
                <button
                  className="waves-effect waves-teal btn-flat accbtn btn-text-overflow"
                  onClick={() => { this.redirectToTarget('/bienvenida4') }}>
                  Tal vez más tarde
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default eatc_alim_bienv3;
