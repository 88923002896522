import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../App.css";
import moment from 'moment';
// import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
// Import Materialize
import M from "materialize-css";
import HelpService from "../../services/HelpService";
import RegisterService from "../../services/RegisterService";
import Preloader from '../preloader/Preloader';

export class eatc_alim_reg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showProgressBarMunicipios: false,
            showProgressBarEnviando: false,
            _id: "",
            fecha_inicio_la_atencin_o_vinculacion: "",
            tipo_documento: "",
            error_tipo_documento: "Seleccione un tipo de identificación!",
            numero_documento: "",
            error_numero_documento: "Proporcione un número de identificación!",
            primer_nombre: "",
            error_primer_nombre: "Proporcione un primer nombre!",
            segundo_nombre: "",
            error_segundo_nombre: "",
            primer_apellido: "",
            error_primer_apellido: "Proporcione un primer apellido!",
            segundo_apellido: "",
            error_segundo_apellido: "",
            edad: "",
            error_edad: "",
            sexo: "",
            error_sexo: "",
            municipio_residencia: "",
            error_municipio_residencia: "Proporcione un municipio de residencia!",
            numero_telefonico: "",
            error_numero_telefonico: "Proporcione un número telefónico!",
            correo_electronico: "",
            error_correo_electronico: "Proporcione un correo electrónico!",
            lugar_voluntariado: "ND",
            dias: "ND",
            horario: 0,
            error: "",
            value: "",
            points: [],
            infoMunicipios: []
        };
        this.options = ["Bogotá", "Medellín", "Cali"];
        this.optionsMinus = ["bogota", "medellin", "cali"];
        this.setValue = this.setValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    setValue(value) {
        console.log("value", value)
        this.setState({ value });
    }

    handleChange(e) {
        console.log(e);
        this.setValue(e.target.value);
        switch (e.target.value) {
            case "0":
                console.log("0");
                break;

            case "1":
                console.log("1");
                this.getAllDonationPoints(this.optionsMinus[1 - 1]);
                break;

            case "2":
                console.log("2");
                this.getAllDonationPoints(this.optionsMinus[2 - 1]);
                break;

            case "3":
                console.log("3");
                this.getAllDonationPoints(this.optionsMinus[3 - 1]);
                break;

            default:
                console.log("default");
                break;
        }
    }

    componentDidMount() {
        M.AutoInit();
        M.updateTextFields();
        this.getMunicipios();
        this.tipo_documento.focus();
        console.log("...DIA Y HORA ACTUAL ", moment().format("YYYY-MM-DD HH:mm:ss"));
    }

    redirectToDashboard = () => {
        console.log("redirect");
        //this.props.history.push("/dashboard");
        this.props.history.push({
            pathname: "/dashboard"
        });
    };

    redirectToLogin = () => {
        console.log("redirect");
        this.props.history.push("/login");
        /*this.props.history.push({
            pathname: "/login"
        });*/
    };

    getMunicipios = () => {
        console.log("...BUSCANDO EN EL API, TODOS LOS MUNICIPIOS DEL PAIS");
        this.setState({
            showProgressBarMunicipios: !this.state.showProgressBarMunicipios
        });
        HelpService.getAllMunicipios()
            .then(infoMunicipios => {
                console.log("...OCURRIO UN EXITO (MUNICIPIOS) -> ", infoMunicipios);
                console.log("...GUARDANDO EN EL STATE INFOMUNICIPIOS -> ", infoMunicipios);
                this.setState({
                    showProgressBarMunicipios: !this.state.showProgressBarMunicipios,
                    infoMunicipios: infoMunicipios
                });
                //this.redirectToCodigo();
            }
            )
            .catch(err => {
                console.log("...OCURRIO UN ERROR (MUNICIPIOS)-> ", err);
                var infoMunicipios = [];
                this.setState({
                    showProgressBarMunicipios: !this.state.showProgressBarMunicipios,
                    infoMunicipios: infoMunicipios
                });
            });
    }

    /*handleSignIn = async e => {
      e.preventDefault();
      const { email, password } = this.state;
      if (!email || !password) {
        this.setState({ error: "Preencha e-mail e senha para continuar!" });
      } else {
        try {
          const response = await api.post("/sessions", { email, password });
          login(response.data.token);
          this.props.history.push("/app");
        } catch (err) {
          this.setState({
            error:
              "Houve um problema com o login, verifique suas credenciais. T.T"
          });
        }
      }
    };*/

    handleSignUp = e => {
        e.preventDefault();
        /*const { tipo_documento, numero_documento, primer_nombre,
            primer_apellido, municipio_residencia, correo_electronico } = this.state;*/
        const { tipo_documento, numero_documento, primer_nombre, segundo_nombre,
            primer_apellido, segundo_apellido, edad, sexo, municipio_residencia,
            numero_telefonico, correo_electronico } = this.state;
        if (!correo_electronico) {
            this.setState({ error_correo_electronico: "Proporcione un correo electrónico!" });
            this.correo_electronico.focus();
        } else {
            this.setState({ error_correo_electronico: "" });
        }
        if (!numero_telefonico) {
            this.setState({ error_numero_telefonico: "Proporcione un número telefónico!" });
            this.numero_telefonico.focus();
        } else {
            this.setState({ error_numero_telefonico: "" });
        }
        if (!municipio_residencia) {
            this.setState({ error_municipio_residencia: "Proporcione un municipio de residencia!" });
            this.municipio_residencia.focus();
        } else {
            this.setState({ error_municipio_residencia: "" });
        }
        if (edad < 18) {
            this.setState({ error_edad: "Debes ser mayor de edad!" });
            this.edad.focus();
        } else {
            this.setState({ error_edad: "" });
        }
        if (!primer_apellido) {
            this.setState({ error_primer_apellido: "Proporcione un primer apellido!" });
            this.primer_apellido.focus();
        } else {
            this.setState({ error_primer_apellido: "" });
        }
        if (!primer_nombre) {
            this.setState({ error_primer_nombre: "Proporcione un primer nombre!" });
            this.primer_nombre.focus();
        } else {
            this.setState({ error_primer_nombre: "" });
        }
        if (!numero_documento) {
            this.setState({ error_numero_documento: "Proporcione un número de identificación!" });
            this.numero_documento.focus();
        } else {
            this.setState({ error_numero_documento: "" });
        }
        if (!tipo_documento) {
            this.setState({ error_tipo_documento: "Seleccione un tipo de identificación!" });
            this.tipo_documento.focus();
        } else {
            this.setState({ error_tipo_documento: "" });
        }

        if (!tipo_documento || !numero_documento || !primer_nombre || !primer_apellido ||
            !municipio_residencia || !numero_telefonico || !correo_electronico || (edad < 18)) {
            console.log("...ALGUN DATO ESTA MAL PROPORCIONADO "
                , "\ntipo_documento ", tipo_documento
                , "\nnumero_documento ", numero_documento
                , "\nprimer_nombre ", primer_nombre
                , "\nprimer_apellido ", primer_apellido
                , "\nedad ", edad
                , "\nmunicipio_residencia ", municipio_residencia
                , "\nnumero_telefonico ", numero_telefonico
                , "\ncorreo_electronico ", correo_electronico);
        } else {
            console.log("...REGISTRANDO USUARIO");
            RegisterService.setNewRegister(moment().format("YYYY-MM-DD HH:mm:ss"),
                tipo_documento, numero_documento, primer_nombre, segundo_nombre,
                primer_apellido, segundo_apellido, edad, sexo, municipio_residencia,
                numero_telefonico, correo_electronico, "ND", "ND", "0", "0")
                //RegisterService.setNewRegister("", "cédula", "71773955", "Jorge", "William", "Correa", "Toro", "43", "masculino", "Medellín", "3113581681", "jorgecorrea@nodrizza.com")
                .then(infoRegister => {
                    console.log("Ocurrió un exito -> ", infoRegister);
                    this.redirectToLogin();
                }
                )
                .catch(err => {
                    console.log("Ocurrió un error -> ", err);
                    this.setState({
                        error:
                            "Datos invalidos, verifique sus datos e intentelo nuevamente"
                    });
                });
        }
    };

    onChange = e => {
        const target = e.target;
        const name = target.name;
        const errorName = 'error_' + name;
        //console.log('target ', target);
        //console.log('name ', name);
        //console.log('errorName ', errorName);
        this.setState({
            [name]: e.target.value,
            [errorName]: ""
        })
        if (name === "edad") {
            console.log('...LLAMA LA INFO DE ESE PUNTO ', name);
            console.log('...POINTS ', this.state.edad);
        }
    }

    redirectToTargetBack = () => {
        console.log("...REDIRIGIR ATRAS");
        this.props.history.goBack();
    };

    render() {
        const divStyle = {
            fontSize: "xx-large"
        };
        const options = this.state.infoMunicipios.map((elem, index) => (
            <option key={elem["_id"]} value={elem["nombre_municipio"]}>
                {elem["nombre_municipio"]}
            </option>
        ));
        return (
            <div>
                <div id="voluntarios" className="pagina">
                    <div className="navbar-fixed">
                        <nav>
                            <div className="nav-wrapper">
                                <h1 className="brand-logo logo-con-boton">
                                </h1>
                                <ul id="nav-mobile" className="left">
                                    <li>
                                        {/* <button
                                            className="accbtn"
                                            onClick={this.redirectToTargetBack}>
                                            <i className="material-icons fixed_eatc" style={divStyle}>
                                                chevron_left</i>
                                        </button> */}
                                        <button
                                            className="backButton accbtn"
                                            onClick={() => { this.redirectToTargetBack() }}>
                                            <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                                        </button>
                                        {/* <Link to="#" className="accbtn" onClick={() => { this.redirectToTargetBack() }}>
                                            <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                                        </Link> */}
                                        {/* <Link to="/login" className="accbtn">
                                            <i className="material-icons fixed_eatc" style={divStyle}>
                                                chevron_left</i>
                                        </Link> */}
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div className="container">
                        <div className="row">
                            <form onSubmit={this.handleSignUp}>
                                <div className="col s12 m10 offset-m1 l4 offset-l4">
                                    <h2>Regístrate</h2>
                                    <br />
                                    <div className="input-field">
                                        <select
                                            id='type_doc'
                                            name='tipo_documento'
                                            className="browser-default"
                                            ref={(input) => { this.tipo_documento = input; }}
                                            onChange={this.onChange}
                                            value={this.state.tipo_documento}>
                                            <option value="" disabled>Tipo de documento *</option>
                                            <option value="cédula">Cédula de ciudadanía</option>
                                            <option value="cédula de extranjeria">Cédula de extranjería</option>
                                            <option value="pasaporte">Pasaporte</option>
                                        </select>
                                        {this.state.error_tipo_documento &&
                                            <p className="txt-fucsia">{this.state.error_tipo_documento}</p>}
                                    </div>
                                </div>
                                <div className="col s12 m10 offset-m1 l4 offset-l4">
                                    <div className="input-field">
                                        <input
                                            placeholder=""
                                            id="num_doc"
                                            autoComplete="document"
                                            name='numero_documento'
                                            type="number"
                                            className="validate"
                                            ref={(input) => { this.numero_documento = input; }}
                                            onChange={this.onChange}
                                            value={this.state.numero_documento}
                                        />
                                        <label>Número de documento *</label>
                                        {this.state.error_numero_documento &&
                                            <p className="txt-fucsia">{this.state.error_numero_documento}</p>}
                                    </div>
                                </div>
                                <div className="col s12 m10 offset-m1 l4 offset-l4">

                                    <div className="input-field">
                                        <input
                                            placeholder="Primer nombre"
                                            id="first_name"
                                            autoComplete="name"
                                            name='primer_nombre'
                                            type="text"
                                            className="validate"
                                            ref={(input) => { this.primer_nombre = input; }}
                                            onChange={this.onChange}
                                            value={this.state.primer_nombre}
                                        />
                                        <label>Primer nombre *</label>
                                        {this.state.error_primer_nombre &&
                                            <p className="txt-fucsia">{this.state.error_primer_nombre}</p>}
                                    </div>
                                    <div className="input-field">
                                        <input
                                            placeholder="Segundo nombre"
                                            id="second_name"
                                            autoComplete="name"
                                            name='segundo_nombre'
                                            type="text"
                                            className="validate"
                                            onChange={this.onChange}
                                            value={this.state.segundo_nombre}
                                        />
                                        <label>Segundo nombre</label>
                                        {this.state.error_segundo_nombre &&
                                            <p className="txt-fucsia">{this.state.error_segundo_nombre}</p>}
                                    </div>
                                    <div className="input-field">
                                        <input
                                            placeholder="Primer apellido"
                                            id="last_name"
                                            autoComplete="lastname"
                                            name='primer_apellido'
                                            type="text"
                                            className="validate"
                                            ref={(input) => { this.primer_apellido = input; }}
                                            onChange={this.onChange}
                                            value={this.state.primer_apellido}
                                        />
                                        <label>Primer apellido *</label>
                                        {this.state.error_primer_apellido &&
                                            <p className="txt-fucsia">{this.state.error_primer_apellido}</p>}
                                    </div>
                                    <div className="input-field">
                                        <input
                                            placeholder="Segundo apellido"
                                            id="second_last_name"
                                            autoComplete="lastname"
                                            name='segundo_apellido'
                                            type="text"
                                            className="validate"
                                            onChange={this.onChange}
                                            value={this.state.segundo_apellido}
                                        />
                                        <label>Segundo apellido</label>
                                        {this.state.error_segundo_apellido &&
                                            <p className="txt-fucsia">{this.state.error_segundo_apellido}</p>}
                                    </div>
                                </div>
                                <div className="col s12 m10 offset-m1 l4 offset-l4">
                                    <div className="input-field col s6">
                                        <input
                                            placeholder="Edad"
                                            id="age"
                                            autoComplete="age"
                                            name='edad'
                                            type="number"
                                            className="validate"
                                            ref={(input) => { this.edad = input; }}
                                            onChange={this.onChange}
                                            value={this.state.edad}
                                        />
                                        <label>Edad</label>
                                        {this.state.error_edad &&
                                            <p className="txt-fucsia">{this.state.error_edad}</p>}
                                    </div>
                                    <div className="input-field col s6">
                                        <select
                                            id="sex"
                                            name='sexo'
                                            className="browser-default"
                                            onChange={this.onChange}
                                            value={this.state.sexo}>
                                            <option value="" disabled>Género</option>
                                            <option value="masculino">Masculino</option>
                                            <option value="femenino">Femenino</option>
                                        </select>
                                        {this.state.error_sexo &&
                                            <p className="txt-fucsia">{this.state.error_sexo}</p>}
                                    </div>
                                </div>
                                <div className="col s12 m10 offset-m1 l4 offset-l4">
                                    {this.state.showProgressBarMunicipios && <Preloader />}
                                    {!this.state.showProgressBarMunicipios &&
                                        <div className="input-field">
                                            <select
                                                name='municipio_residencia'
                                                className="browser-default"
                                                ref={(input) => { this.municipio_residencia = input; }}
                                                onChange={this.onChange}
                                                value={this.state.municipio_residencia}>
                                                <option value="" disabled>Municipio *</option>
                                                {options}
                                            </select>
                                            {this.state.error_municipio_residencia &&
                                                <p className="txt-fucsia">{this.state.error_municipio_residencia}</p>}
                                        </div>
                                    }
                                </div>
                                <div className="col s12 m10 offset-m1 l4 offset-l4">
                                    <div className="input-field">
                                        <input
                                            placeholder=""
                                            autoComplete="phone"
                                            id="tel"
                                            type="number"
                                            className="validate"
                                            name='numero_telefonico'
                                            ref={(input) => { this.numero_telefonico = input; }}
                                            onChange={this.onChange}
                                            value={this.state.numero_telefonico} />
                                        <label>Número telefónico *</label>
                                        {this.state.error_numero_telefonico &&
                                            <p className="txt-fucsia">{this.state.error_numero_telefonico}</p>}
                                    </div>
                                </div>
                                <div className="col s12 m10 offset-m1 l4 offset-l4">
                                    <div className="input-field">
                                        <input
                                            placeholder=""
                                            id="email"
                                            type="email"
                                            autoComplete="email"
                                            className="validate"
                                            name='correo_electronico'
                                            ref={(input) => { this.correo_electronico = input; }}
                                            onChange={this.onChange}
                                            value={this.state.correo_electronico} />
                                        <label>Correo electrónico *</label>
                                        {this.state.error_correo_electronico &&
                                            <p className="txt-fucsia">{this.state.error_correo_electronico}</p>}
                                    </div>
                                </div>
                                <div className="col s12 m10 offset-m1 l4 offset-l4">
                                    <p style={{ color: 'black' }}>Obligatorio (*)</p>
                                    {this.state.error &&
                                        <p className="txt-fucsia">{this.state.error}</p>}
                                    <br />
                                    <button
                                        type='submit'
                                        className="waves-effect waves-light btn-large accbtn btn-text-overflow">
                                        Enviar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default eatc_alim_reg;