import API from "./config";

const RATIONS_ENDPOINTS = {
  ALL_INFO: "raciones_acumuladas_alimentaton?dia=_*", //URI para llamar a todas los alimentos.
  RATIONS_INFO: "raciones_acumuladas_alimentaton?dia=" //URI para llamar alimentos por categoria.
};

const RacionesService = {
  getAll: () =>
    new Promise((resolve, reject) => {
      API.get(RATIONS_ENDPOINTS.ALL_INFO)
        .then(res => {
          console.log("$...RES.DATA -> ", res.data);
          console.log("$...RES.STATUS -> ", res.status);
          console.log("$...RES.STATUSTEXT -> ", res.statusText);
          return res.data.res
        })
        .then(data => {
          resolve(data);
          console.log("$...DATA -> ", data);
          console.log("$...DATA.FIRST_POSITION -> ", data[0]);
        })
        .catch(err => {
          console.log("$...ERROR -> ", err);
          reject(err);
        });
    }), // Funcion que nos devolverá el valor de todas las peliculas
  getRations: (fecha) =>
    new Promise((resolve, reject) => {
      API.get(RATIONS_ENDPOINTS.RATIONS_INFO + fecha)
        .then(res => {
          console.log("$...RES.DATA -> ", res.data);
          console.log("$...RES.STATUS -> ", res.status);
          console.log("$...RES.STATUSTEXT -> ", res.statusText);
          return res.data.res
        })
        .then(data => {
          resolve(data);
          console.log("$...DATA -> ", data);
          console.log("$...DATA.FIRST_POSITION -> ", data[0]);
        })
        .catch(err => {
          console.log("$...ERROR -> ", err);
          reject(err);
        });
    })
};

export default RacionesService;
