import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../css/custom.css"
import "../../App.css";
// import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
// Import Materialize
import M from "materialize-css";
import { isPoints } from "../../services/auth";

export class eatc_alim_dona_mon extends Component {
  componentDidMount() {
    M.AutoInit();
    //M.updateTextFields();
  }


  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  redirectToTargetBack = () => {
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  render() {
    const divStyle = {
      fontSize: "xx-large"
    };
    return (
      <div>
        <div id="voluntarios" className="pagina">
          <div className="navbar-fixed">
            <nav>
              <div className="nav-wrapper">
                <h1 className="brand-logo logo-con-boton">
                  Donación de Dinero
                </h1>
                <ul id="nav-mobile" className="left">
                  <li>
                    {/* <button
                      className="accbtn"
                      onClick={() => { this.redirectToBack() }}>
                      <i className="material-icons fixed_eatc" style={divStyle}>
                        chevron_left</i>
                    </button> */}
                    <button
                      className="backButton accbtn"
                      onClick={() => { this.redirectToTargetBack() }}>
                      <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                    </button>
                    {/* <Link to="#" className="accbtn" onClick={() => { this.redirectToTargetBack() }}>
                      <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                    </Link> */}
                    {/* <Link to="/dashboard" className="accbtn">
                      <i className="material-icons fixed_eatc" style={divStyle}>
                        chevron_left
                      </i>
                    </Link> */}
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className="container">
            <div className="row pad-bot-pag">
              <div className="col s12 m10 offset-m1 l4 offset-l4">
                <h4>Elige el monto de tu donación</h4>
                <br />
                <a
                  href="https://biz.payulatam.com/L07c67fEC9CCD78"
                  className="waves-effect waves-light btn-large mrg-btn accbtn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  $10.000
                </a>
                <a
                  href="https://biz.payulatam.com/L07c67f4E01F53A"
                  className="waves-effect waves-light btn-large mrg-btn accbtn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  $25.000
                </a>
                <a
                  href="https://biz.payulatam.com/L07c67fC5EABA10"
                  className="waves-effect waves-light btn-large mrg-btn accbtn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  $50.000
                </a>
                <a
                  href="https://biz.payulatam.com/L07c67fCB76BB4B"
                  className="waves-effect waves-light btn-large mrg-btn accbtn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  $100.000
                </a>
                <a
                  href="https://biz.payulatam.com/L07c67f116F0369"
                  className="waves-effect waves-light btn-large mrg-btn accbtn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  $500.000
                </a>
                <a
                  href="https://biz.payulatam.com/L07c67fCDFB0362"
                  className="waves-effect waves-light btn-large mrg-btn accbtn"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  $1.000.000
                </a>
                <br />
                <button
                  className="waves-effect waves-teal btn-flat accbtn btn-text-overflow"
                  onClick={() => { this.redirectToTarget('/terminos') }}>
                  Conoce los términos de servicio
                </button>
                {/* <Link
                  to="/terminos"
                  className="waves-effect waves-teal btn-flat accbtn">
                  Conoce los términos de servicio
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- footer --> */}
        {isPoints() &&
          <footer className="page-footer footer-fixed" id="eatc-usr-fn">
            <div className="container-footer">
              <div className="row">
                <div className="col l4 s4">
                  <button
                    className="waves-effect waves-light btn accbtn"
                    onClick={() => { this.redirectToTarget('/donacion_alimentos') }}>
                    <i className="material-icons top">shopping_basket</i>
                    <span>Donar Alimentos</span>
                  </button>
                  {/* <Link
                    to="/donacion_alimentos"
                    className="waves-effect waves-light btn accbtn">
                    <i className="material-icons top">shopping_basket</i>
                    <span>Donar Alimentos</span>
                  </Link> */}
                </div>
                <div className="col l4 s4 center-align">
                  <button
                    className="waves-effect waves-light btn accbtn"
                    onClick={() => { this.redirectToTarget('/voluntarios') }}>
                    <i className="material-icons top">favorite_border</i>
                    <span>Tiempo como voluntario</span>
                  </button>
                  {/* <Link
                    to="/voluntarios"
                    className="waves-effect waves-light btn accbtn">
                    <i className="material-icons top">favorite_border</i>
                    <span>Tiempo como voluntario</span>
                  </Link> */}
                </div>
                <div className="col l4 s4">
                  <button
                    className="waves-effect waves-light btn accbtn"
                    onClick={() => { this.redirectToTarget('/dinero') }}>
                    <i className="material-icons top">credit_card</i>
                    <span>Donar<br />dinero</span>
                  </button>
                  {/* <Link
                    to="/dinero"
                    className="waves-effect waves-light btn accbtn">
                    <i className="material-icons top">credit_card</i>
                    <span>Donar<br />dinero</span>
                  </Link> */}
                </div>
              </div>
            </div>
          </footer>
        }
      </div>
    );
  }
}

export default eatc_alim_dona_mon;
