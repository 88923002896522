import React, { Component } from "react";
import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../App.css";
import codigodedonacion from "../../img/codigo de donacion@3x.png";
// Import Materialize
import M from "materialize-css";

export class eatc_alim_dona_cod extends Component {
  componentDidMount() {
    M.AutoInit();
    console.log("...ESTAS EN /codigo");
  }

  redirectToTargetBack = () => {
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  render() {
    const divStyle = {
      fontSize: "xx-large"
    };
    return (
      <div id="dona_cod" className="pagina">
        <div className="navbar-fixed">
          <nav>
            <div className="nav-wrapper">
              <ul id="nav-mobile" className="left">
                <li>
                  {/* <Link to="/dashboard" className="accbtn">
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </Link> */}
                  <Link to="#" className="accbtn" onClick={() => { this.redirectToTarget('/dashboard') }}>
                    <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l6 offset-l3">
              <img
                className="responsive-img"
                src={codigodedonacion}
                alt="codigodedonacion"
              />
            </div>
          </div>
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
              <h2 className="center-align">Gracias por tu generosidad</h2>
              {/*  <h3 className="center-align">
                Presenta este código al voluntario en tu punto de donación
              </h3> */}
            </div>
          </div>
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
              {/* <p className="big fucsia center-align" id="codigo-dona">
                  cek02156f
                </p> */}
              <button
                className="waves-effect waves-light btn-large accbtn btn-violeta mrg-btns btn-text-overflow"
                onClick={() => { this.redirectToTarget('/voluntarios') }}>
                ¿Quieres donar tiempo?
              </button>
              <button
                className="waves-effect waves-light btn-large accbtn btn-violeta mrg-btns btn-text-overflow"
                onClick={() => { this.redirectToTarget('/dinero') }}>
                ¿Quieres donar dinero?
              </button>
              <button
                className="waves-effect waves-light btn-large accbtn mrg-btns btn-text-overflow"
                onClick={() => { this.redirectToTarget('/dashboard') }}>
                Ir al Inicio
              </button>
              {/* <Link
                to="/voluntarios"
                className="waves-effect waves-light btn-large accbtn btn-violeta mrg-btns btn-text-overflow">
                ¿Quieres donar tiempo?
                </Link>
              <Link
                to="/dinero"
                className="waves-effect waves-light btn-large accbtn btn-violeta mrg-btns btn-text-overflow">
                ¿Quieres donar dinero?
                </Link> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default eatc_alim_dona_cod;
