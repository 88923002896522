import React from 'react'
import { Map as LeafletMap, TileLayer, Marker, Popup} from 'react-leaflet';
//import Control from "react-leaflet-control";
//import SelectCiudades from '../ciudades/SelectCiudades';

class Mapa extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      lat: this.props.lat,
      lng: this.props.lng,
      points: this.props.points
    }

  }

  changeCenter = (point) => {
    console.log("cambiar el centro a un \nPunto ", point);
    let curr_lat = parseFloat(point["eatc-lat"].replace(",", "."));
    let curr_lng = parseFloat(point["eatc-lon"].replace(",", "."));
    console.log("cambiar el centro a un \ncurr_lat ", curr_lat);
    console.log("cambiar el centro a un \ncurr_lng ", curr_lng);
    if (point !== null){
      this.setState({
        lat: curr_lat,
        lng: curr_lng,
      })
    }
    
  }

  render() {
    console.log("...ESTAS EN /bienvenida4 - MAPA");
    let position = [];
    if(this.props.lat !== 'err-latitude' && this.props.lng !== 'err-longitude'){
      position = [this.props.lat, this.props.lng]; 
      console.log("...MAPA POSICION STATE", position); 
    }else{  
      position = [6.245255, -75.581703];     
      console.log("...MAPA POSICION DEFAULT", position); 
    }
    return (
      <LeafletMap
        id={"satellite"}
        //center={[51.505, -0.09]}
        center={position}
        zoom={10}
        maxZoom={20}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={true}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
      >
        <TileLayer
          url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
        />
        {
          this.props.points.map(point => {
          return (
            <Marker key={point["_id"]} position={[point["eatc-lat"].replace(",", "."), point["eatc-lon"].replace(",", ".")]}>
              <Popup>
                {point["eatc-name"]}
              </Popup>
            </Marker>
          )
        })
        }
      </LeafletMap>

    );
  }
}

export default Mapa