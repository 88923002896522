//https://donantes.eatcloud.info/crd/abaco/?_tabla=eatc_volunteers&_operacion=insert&fecha_inicio_la_atencin_o_vinculacion=2019-10-24%2011:11:11&tipo_documento=cédula&numero_documento=71773955&primer_nombre=Jorge&segundo_nombre=William&primer_apellido=Correa&segundo_apellido=Toro&edad=43&sexo=masculino&municipio_residencia=Medellín&numero_telefonico=3113581682&correo_electronico=jorgecorrea@nodrizza.com&lugar_voluntariado=ND&dias=ND&horario=0
import axios from 'axios';

const BASE_URL = "https://donantes.eatcloud.info/crd/abaco/";
const API = axios.create({
    baseURL: BASE_URL,
})

//https://donantes.eatcloud.info/crd/abaco/?_tabla=eatc_volunteers&_operacion=update&lugar_voluntariado=Jumbo%20Premium%20Plaza&dias=viernes,sábado&horario_inicio=10:00:00&horario_fin=15:00:00&WHEREnumero_documento=71773955  


const REGISTER_ENDPOINTS = {
    TEST: "?_tabla=eatc_volunteers&_operacion=insert&fecha_inicio_la_atencin_o_vinculacion=2019-10-24%2011:11:11&tipo_documento=cédula&numero_documento=71773955&primer_nombre=Jorge&segundo_nombre=William&primer_apellido=Correa&segundo_apellido=Toro&edad=43&sexo=masculino&municipio_residencia=Medellín&numero_telefonico=3113581682&correo_electronico=jorgecorrea@nodrizza.com&lugar_voluntariado=ND&dias=ND&horario=0",
    INSERT: "?_tabla=eatc_volunteers&_operacion=insert", //URI para llamar a todos los campos de voluntarios.
    UPDATE: "?_tabla=eatc_volunteers&_operacion=update",
    FECHA_INI: "&fecha_inicio_la_atencin_o_vinculacion=",
    TIPO_DOC: "&tipo_documento=",
    NUM_DOC: "&numero_documento=",
    WHERE_NUM_DOC: "&WHEREnumero_documento=",
    PRIM_NOM: "&primer_nombre=",
    SEC_NOM: "&segundo_nombre=",
    PRIM_APE: "&primer_apellido=",
    SEC_APE: "&segundo_apellido=",
    EDAD: "&edad=",
    GENERO: "&sexo=",
    MUN_RES: "&municipio_residencia=",
    NUM_TEL: "&numero_telefonico=",
    EMAIL: "&correo_electronico=",
    LUGAR_VOLUNTARIADO: "&lugar_voluntariado=",
    DIAS: "&dias=",
    HORARIO_INCIO: "&horario_incio=",
    HORARIO_INICIO: "&horario_inicio=",
    HORARIO_FIN: "&horario_fin=",
    OTHER_FIELDS: "&lugar_voluntariado=ND&dias=ND&horario_incio=0&horario_fin=0"
};

const RegisterService = {
    setNewRegister: (fecha, tipodoc, numdoc, primernombre,
        segundonombre, primerapellido, segundoapellido, edad, genero,
        municipioresidencia, numerotelefonico, correoelectronico,
        lugarvoluntariado, dias, horarioinicio, horariofin) =>
        new Promise((resolve, reject) => {
            API.post(REGISTER_ENDPOINTS.INSERT +
                REGISTER_ENDPOINTS.FECHA_INI + fecha +
                REGISTER_ENDPOINTS.TIPO_DOC + tipodoc +
                REGISTER_ENDPOINTS.NUM_DOC + numdoc +
                REGISTER_ENDPOINTS.PRIM_NOM + primernombre +
                REGISTER_ENDPOINTS.SEC_NOM + segundonombre +
                REGISTER_ENDPOINTS.PRIM_APE + primerapellido +
                REGISTER_ENDPOINTS.SEC_APE + segundoapellido +
                REGISTER_ENDPOINTS.EDAD + edad +
                REGISTER_ENDPOINTS.GENERO + genero +
                REGISTER_ENDPOINTS.MUN_RES + municipioresidencia +
                REGISTER_ENDPOINTS.NUM_TEL + numerotelefonico +
                REGISTER_ENDPOINTS.EMAIL + correoelectronico +
                REGISTER_ENDPOINTS.LUGAR_VOLUNTARIADO + lugarvoluntariado +
                REGISTER_ENDPOINTS.DIAS + dias +
                REGISTER_ENDPOINTS.HORARIO_INCIO + horarioinicio +
                REGISTER_ENDPOINTS.HORARIO_FIN + horariofin)
                .then(
                    res => {
                        console.log("$...RES.DATA -> ", res.data);
                        console.log("$...RES.STATUS -> ", res.status);
                        console.log("$...RES.STATUSTEXT -> ", res.statusText);
                        return res.data.res
                    }
                )
                .then(data => {
                    resolve(data);
                    console.log("$...DATA -> ", data);
                    console.log("$...DATA.FIRST_POSITION -> ", data[0]);
                },
                )
                .catch(
                    err => {
                        console.log("$...ERROR -> ",err);
                        reject(err);
                    });
        }),
    updateRegister: (numdoc, lugarvoluntariado, dias, horarioinicio, horariofin) =>
        new Promise((resolve, reject) => {
            API.post(REGISTER_ENDPOINTS.UPDATE +
                REGISTER_ENDPOINTS.LUGAR_VOLUNTARIADO + lugarvoluntariado +
                REGISTER_ENDPOINTS.DIAS + dias +
                REGISTER_ENDPOINTS.HORARIO_INICIO + horarioinicio +
                REGISTER_ENDPOINTS.HORARIO_FIN + horariofin +
                REGISTER_ENDPOINTS.WHERE_NUM_DOC + numdoc)
                .then(
                    res => {
                        console.log(res.data);
                        console.log(res.status);
                        console.log(res.statusText);
                        return res.data.res
                    }
                )
                .then(data => {
                    resolve(data);
                    console.log(data);
                    console.log(data[0]);
                },
                )
                .catch(
                    err => {
                        console.log("error -> ", err);
                        reject(err);
                    });
        }),
};

export default RegisterService;