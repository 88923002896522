import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//mport App from "./App";
import Application from "./Application";
//import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
//import MoviesContainer from "./screen/movies/container/MoviesContainer";

ReactDOM.render(
    <Application />,
  document.getElementById("root")
); 

// ReactDOM.render(<MoviesContainer/>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
