import React, { Component } from "react";
//import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../../css/design_system_styles.css";
//import "../../../css/leaflet.css";
import "../../../css/eatcloud.css";
import "../../../css/custom.css";
import "../../../App.css";
import logoalimentaton from "../../../img/logo alimentaton@3x.png";
// Import Materialize
import M from "materialize-css";
//import service from "../../../services/service";
import PointDonationService from "../../../services/PointDonationService";
import HelpService from "../../../services/HelpService";
import SweetAlert from 'sweetalert2-react';
import Mapa from "../../mapa/Mapa";
//import Map from "../../mapa/Map";
//import ListUsers from "../../mapa/ListUsers";
//import SelectCiudades from "../../ciudades/SelectCiudades";
import Preloader from '../../preloader/Preloader';
import {
  isPoints, setPoints, getPoints, remPoints,
  isGeoLocated, getLat, getLng, isCitied, getCitied, setCitied
} from "../../../services/auth";

export class eatc_alim_bienv4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //latitud: props.location.state.lat,
      //longitud: props.location.state.lng,
      sweetAlertTitle: "",
      sweetAlertText: "",
      sweetAlertMoney: false,
      latitud: "",
      longitud: "",
      points: [],
      infoMunicipios: [],
      ciudad_residencia: "",
      error_ciudad_residencia: "",
      value: "",
      showProgressBarPoints: false,
      showProgressBar: false,
      showSelectMunicipios: false,
      city: ""
    };
    this.options = ["Bogotá", "Medellín", "Cali"];
    this.optionsMinus = ["bogota", "medellin", "cali"];
  }

  componentDidMount() {
    // Auto initialize all the things!
    M.AutoInit();
    console.log("...ESTAS EN /bienvenida4");
    console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY GEOLOCALIZACION");
    if (isGeoLocated()) {
      console.log("...TIENE GUARDADA LA GEOLOCALIZACION EN EL LOCALSTORAGE Y LA TOMO \nLAT", getLat(), "\nLNG", getLng());
      console.log("...GUARDADO EN EL STATE LATITUD-LONGITUD");
      this.setState({
        latitud: getLat(),
        longitud: getLng(),
      })
      if(getLat() !== "err-latitude" && getLng() !== "err-longitude"){
        console.log("...TIENE GUARDADA UNA GEOLOCALIZACION VALIDA");
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY CITY");
        if (isCitied()) {
          console.log("...HAY CITY EN EL LOCALSTORAGE Y LA TOMO ", getCitied());
          this.getAllDonationPointsCity(getCitied())
        } else {
          //this.getMunicipios(); //TODOS LOS MUNICIPIOS
          console.log("...NO HAY CITY EN EL LOCALSTORAGE");
          console.log("...CARGO TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION");
          this.getMunicipiosDonations(); //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION
        }
      }else{
        console.log("...TIENE GUARDADA UNA GEOLOCALIZACION CON ERROR");
        console.log("...CARGO TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION");
        this.getMunicipiosDonations(); //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION
      }
    } else {
      //LA DEBERIA PEDIR DE NUEVO?
      console.log("...NO TIENE GUARDADA LA GEOLOCALIZACION");
      this.getMunicipiosDonations(); //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION
    }
  }

  setValue = (value) => {
    console.log("value", value)
    this.setState({ value });
  }

  donationsPointsCheck = () => {
    console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY PUNTOS");
    if (isPoints()) {
      console.log("...SI HAY PUNTOS EN EL LOCALSTORAGE");
      this.redirectToTarget('/dashboard');
    } else {
      console.log("...SI NO HAY PUNTOS EN EL LOCALSTORAGE MUESTRO UN ALERT");
      this.setState({
        show: true,
        sweetAlertMoney: true,
        sweetAlertTitle: "NO EXISTEN PUNTOS CERCANOS",
        sweetAlertText: "Aunque no tienes puntos de donación cercanos,\nvincúlate donando dinero.",
      })
    }
  }

  handleClick = () => {
    if (this.state.points.length > 0) {
      //setPoints(this.state.points);
      isPoints() ? console.log("tiene puntos ", JSON.parse(getPoints())) : console.log("NO tiene puntos");
      //localStorage.setItem([], this.state.points);
      this.redirectToDashboard(this.state.points);
    }
    else {
      //alert("NO HAY PUNTOS CERCANOS");
      isPoints() ? console.log("tiene puntos ", JSON.parse(getPoints())) : console.log("NO tiene puntos", isPoints());
      if (!isPoints()) {
        this.setState({
          show: true,
          sweetAlertMoney: true,
          sweetAlertTitle: "NO EXISTEN PUNTOS CERCANOS",
          sweetAlertText: "Aunque no tienes puntos de donación cercanos,\nvincúlate donando dinero.",
        })
      }
    }
  }

  redirectToDashboard = (points) => {
    console.log("redirect", points);
    this.props.history.push({
      pathname: "/dashboard", state: {
        points: points,
      }
    });
  };

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  redirectToTargetBack = () => {
    console.log("...REDIRIGIR ATRAS");
    this.props.history.goBack();
  };

  getAllDonationPointsCity = (city) => {
    console.log('...OBTENGO TODOS LOS PUNTOS DE DONACION POR CITY (', city, ') (ASINCRONO)');
    this.setState({
      showProgressBarPoints: !this.state.showProgressBarPoints,
    })
    PointDonationService.getCity(city)
      .then(points => {
        console.log("@...OBTENGO PUNTOS (EXITO) -> ", points);
        console.log("@...GUARDA EN EL STATE POINTS, LATITUD(firstPosition), LONGITUD(firstPosition)");
        this.setState({
          showProgressBarPoints: !this.state.showProgressBarPoints,
          points: points,
          latitud: parseFloat(points[0]["eatc-lat"].replace(",", ".")),
          longitud: parseFloat(points[0]["eatc-lon"].replace(",", "."))
        })
        //this.changeCenter(points[0]);
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY PUNTOS");
        if (isPoints()) {
          console.log("...SI HAY PUNTOS EN EL LOCALSTORAGE BORRO");
          remPoints();
          console.log("...AGREGO NUEVOS PUNTOS EN EL LOCALSTORAGE");
          setPoints(points);
        } else {
          console.log("...SI NO HAY PUNTOS EN EL LOCALSTORAGE AGREGO");
          setPoints(points);
        }

      })
      .catch(err => {
        console.log("@...NO OBTUVE PUNTOS (ERROR) -> ", err);
        const points = [];
        this.setState({
          showProgressBarPoints: !this.state.showProgressBarPoints,
          points: points
        })
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY PUNTOS");
        if (isPoints()) {
          console.log("...SI, HAY PUNTOS EN EL LOCALSTORAGE BORRO");
          remPoints();
          this.getMunicipiosDonations(); //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION       
        }else{
          console.log("...NO, HAY PUNTOS EN EL LOCALSTORAGE");
          this.getMunicipiosDonations(); //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION
        }
      });
  };

  getAllDonationPoints = () => {
    PointDonationService.getCity('medellin')
      .then(points =>
        this.setState({
          points: points
        })
      )
      .catch(err => console.log("Ocurrió un error", err));
  };

  //TODOS LOS MUNICIPIOS
  getMunicipios = () => {
    console.log("...OBTIENE LOS MUNICIPIOS (ASINCRONO)");
    this.setState({
      showProgressBar: !this.state.showProgressBar
    });
    HelpService.getAllMunicipios()
      .then(infoMunicipios => {
        console.log("@...MUNICIPIOS (EXITO) -> ", infoMunicipios);
        console.log("@...GUARDA EN EL STATE INFOMUNICIPIOS");
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          showSelectMunicipios: !this.state.showSelectMunicipios,
          infoMunicipios: infoMunicipios,
        });
      }
      )
      .catch(err => {
        console.log("@...MUNICIPIOS (ERROR) -> ", err);
        let infoMunicipios = []
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          infoMunicipios: infoMunicipios,
        });
      });
  }

  //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION
  getMunicipiosDonations = () => {
    console.log('...OBTENGO TODOS LOS MUNICIPIOS CON PUNTO DE DONACION (ASINCRONO)');
    this.setState({
      showProgressBar: !this.state.showProgressBar
    });
    PointDonationService.getAll()
      .then(infoMunicipios => {
        console.log("@...OBTENGO MUNICIPIOS (EXITO) -> ", infoMunicipios);
        const distinctCities = [...new Set(infoMunicipios.map(x => x["eatc-city"]))];
        console.log("@...FILTRO MUNICIPIOS -> ", distinctCities);
        console.log("@...GUARDA EN EL STATE LOS MUNICIPIOS DONANTES");
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          showSelectMunicipios: !this.state.showSelectMunicipios,
          infoMunicipios: distinctCities
        });
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY PUNTOS");
        if (isPoints()) {
          console.log("...SI HAY PUNTOS EN EL LOCALSTORAGE BORRO");
          remPoints();
        }
      }
      )
      .catch(err => {
        console.log("@...NO OBTUVE MUNICIPIOS (ERROR) -> ", err);
        var infoMunicipios = [];
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          infoMunicipios: infoMunicipios
        });
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY PUNTOS");
        if (isPoints()) {
          console.log("...SI HAY PUNTOS EN EL LOCALSTORAGE BORRO");
          remPoints();
        }
      });
  }

  orderAndFirstElementArray = (arr, item) => {
    arr.sort()
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    return arr.unshift(item);
  }

  onChange = e => {
    console.log("...ON CHANGE");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const errorName = 'error_' + name;
    console.log('...target ', target);
    console.log('...name ', name);
    console.log('...value ', value);
    console.log('...errorName ', errorName);
    console.log("...GUARDA EN EL STATE ", name);
    this.setState({
      [name]: value,
      [errorName]: ""
    })
    if (name === "ciudad_residencia") {
      console.log('...LLAMA LA INFO DE ESE PUNTO ', name);
      console.log('...POINTS ', this.state.points);
      setCitied(value)
      this.getAllDonationPointsCity(value);
      /*const currentPoint = this.state.points.filter(point => point["eatc-id"] === value);
      this.setState({
        currentPoint: currentPoint
      })*/
    }
  }

  render() {

    //TODOS LOS MUNICIPIOS
    /*this.state.infoMunicipios.sort ((a, b) => a["nombre_municipio"].localeCompare (b["nombre_municipio"]));
    const options = this.state.infoMunicipios.map((elem, index) => (
      <option key={elem["_id"]} value={elem["nombre_municipio"]}>
        {elem["nombre_municipio"]}
      </option>
    ));*/

    //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION y PRIMERO BOGOTÁ
    this.orderAndFirstElementArray(this.state.infoMunicipios, 'Bogotá');
    const options = this.state.infoMunicipios.map((elem, index) => (
      <option key={index} value={elem}>
        {elem}
      </option>
    ));

    const divStyle = {
      fontSize: "xx-large"
    };

    return (

      <div id="bienvenida4" className="pagina">
        <div className="navbar-fixed">
          <nav>
            <div className="nav-wrapper">
              <ul id="nav-mobile" className="left">
                <li>
                  <button
                    className="backButton accbtn"
                    onClick={() => { this.redirectToTargetBack() }}>
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </button>
                  {/* <Link to="#" className="accbtn" onClick={() => { this.redirectToTargetBack() }}>
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </Link> */}
                  {/* <Link to="/bienvenida2" className="accbtn">
                    <i className="material-icons fixed_eatc" style={divStyle}>
                      chevron_left
                    </i>
                  </Link> */}
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div className="container">
          <div className="row">
            <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
              <h2 className="center-align">
                ¡Hay un punto de donación cerca a ti!
              </h2>
              {this.state.showProgressBarPoints && <Preloader />}
              {!this.state.showProgressBarPoints &&
                <h3 className="center-align">
                  Hemos encontrado
                <br />
                  <span className="txt-fucsia">
                    <span id="cant-pnt-dona">{this.state.points.length}</span> puntos de donación
                    ALIMENTATÓN
                </span>
                  {/* <br />a <span id="dist-pnt-dona">9km</span> de ti. */}
                  <br />cerca de ti.
              </h3>
              }
              <br />
            </div>
            <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
              {this.state.showProgressBar && <Preloader />}
              {this.state.showSelectMunicipios &&
                <div className="input-field">
                  <select
                    name='ciudad_residencia'
                    className="browser-default"
                    onChange={this.onChange}
                    value={this.state.ciudad_residencia}>
                    <option value="" disabled>Ciudad de residencia *</option>
                    {options}
                  </select>
                  {this.state.error_ciudad_residencia && <p style={{ color: 'red' }}>{this.state.error_ciudad_residencia}</p>}
                </div>
              }
            </div>
            {/*this.state.showProgressBar && <Preloader />*/}
            {/*this.state.showSelectMunicipios &&
              <div className="input-field">
                <input
                  id="ciudad_residencia"
                  type="text"
                  min="3" max="12"                  
                  className="validate"
                  placeholder="Ciudad de residencia *"
                  onChange={this.onChange}
                  value={this.state.ciudad_residencia}
                  list="cities"
                  name="ciudad_residencia"
                />
                <datalist id="cities">
                  <option value="" disabled>Ciudad de residencia *</option>
                  {options}
                </datalist>
                {this.state.error_ciudad_residencia && <p style={{ color: 'red' }}>{this.state.error_ciudad_residencia}</p>}
              </div>
            */}
          </div>
        </div>
        <div className="logo-map">
          <img
            className="responsive-img"
            src={logoalimentaton}
            alt="logoalimentaton"
          />
        </div>
        {/* <Map lat={this.state.latitud} lng={this.state.longitud} points={this.state.points} /> */}
        <Mapa className="map-canvas" lat={this.state.latitud} lng={this.state.longitud} points={this.state.points} />
        {/* <div id="map-canvas"> */}

        {/* </div> */}
        <SweetAlert
          show={this.state.show}
          title={this.state.sweetAlertTitle}
          text={this.state.sweetAlertText}
          type='warning'
          confirmButtonColor='#3085d6'
          confirmButtonText='Si, adelante!'
          onConfirm={() => {
            this.setState({
              show: false
            });
            if (this.state.sweetAlertMoney)
              setTimeout(this.redirectToTarget('/dinero'), 2000);
          }
          }
        />
        <div className="container">
          <div className="">
            <div className="row">
              <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4">
                {/* <ListUsers points={this.state.points} /> */}
                <p className="small opacity-54">
                  Te mostramos los puntos más cercanos a tu ubicación actual
                </p>
                <button
                  className="waves-effect waves-light btn-large accbtn btn-text-overflow"
                  onClick={() => { this.donationsPointsCheck() }}
                >
                  ¡Estoy listo para donar!
                </button>
                {/* <Link
                  to="/dashboard"
                  className="waves-effect waves-light btn-large accbtn"
                >
                  ¡Estoy listo para donar!
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default eatc_alim_bienv4;
