import API from "./config";

const DONATIONS_ENDPOINTS = {
  ALL_INFO: "eatc_odds_alimentaton?categoria=_*", //URI para llamar a todas los alimentos.
  ODD_INFO: "eatc_odds_alimentaton?categoria=" //URI para llamar alimentos por categoria.
};

const OddDonationService = {
    getAll: () =>
      new Promise((resolve, reject) => {
        API.get(DONATIONS_ENDPOINTS.ALL_INFO)
        .then(
            res =>
              {console.log(res.data);
              console.log(res.status);
              console.log(res.statusText);
              return res.data.res}
          )
          .then(data =>
            {resolve(data); 
            console.log(data);
            console.log(data[0]);},
            //resolve(data)
          )
          .catch(err => reject(err));
      }), // Funcion que nos devolverá el valor de todas las peliculas
    getAlimentos: (categoria) =>
      new Promise((resolve, reject) => {
        API.get(DONATIONS_ENDPOINTS.ODD_INFO + categoria)
        .then(
          res =>
            {console.log(res.data);
            console.log(res.status);
            console.log(res.statusText);
            return res.data.res}
        )
        .then(data =>
          {resolve(data); 
          console.log(data);
          console.log(data[0]);},
          //resolve(data)
        )
        .catch(
          err => reject(err)
          //{console.log(err);}
        );
      })
  };
  
  export default OddDonationService;
  