import React, { Component } from "react";
import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../App.css";
// import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
// Import Materialize
import M from "materialize-css";
import OddDonationService from "../../services/OddDonationService";
import Preloader from '../preloader/Preloader';

class eatc_alim_dona extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noperecederos: [],
            perecederos: [],
            otros: [],
            alimentos: [],
            showProgressBar: false,
            showCollapsible: true,
        };
        this.options = ["Bogotá", "Medellín", "Cali"];
        this.optionsMinus = ["bogota", "medellin", "cali"];
    }

    componentDidMount() {
        M.AutoInit();
        this.getAllTheAlimentos();
        //this.getAllTheNoPerecederos('no perecederos');
        //this.getAllThePerecederos('perecederos');
        //this.getAllTheOthers('otros');

        //var elem = document.querySelector('.collapsible.expandable');
        //var instance = M.Collapsible.init(elem, {
        //    accordion: false
        //});
    }

    getAllTheAlimentos = () => {
        console.log('getAllTheAlimentos ');
        this.setState({
            //showCollapsible: !this.state.showCollapsible,
            showProgressBar: !this.state.showProgressBar
        });
        OddDonationService.getAll()
            .then(alimentos => {
                console.log('alimentos : ', alimentos);
                this.setState({
                    //showCollapsible: !this.state.showCollapsible,
                    showProgressBar: !this.state.showProgressBar,
                    alimentos: alimentos,
                })
            })
            .catch(err => {
                console.log("Ocurrió un error", err)
                this.setState({
                    //showCollapsible: !this.state.showCollapsible,
                    showProgressBar: !this.state.showProgressBar
                });
            });
    }

    getAllTheNoPerecederos = (categoria) => {
        console.log('getAllDonationPointsCity ');
        OddDonationService.getAlimentos(categoria)
            .then(noperecederos => {
                console.log('noperecederos : ', noperecederos);
                this.setState({
                    noperecederos: noperecederos,
                })
            }
            )
            .catch(err => console.log("Ocurrió un error", err));
    }

    getAllThePerecederos = (categoria) => {
        console.log('getAllThePerecederos ');
        OddDonationService.getAlimentos(categoria)
            .then(perecederos => {
                console.log('perecederos : ', perecederos);
                this.setState({
                    perecederos: perecederos,
                })
            }
            )
            .catch(err => console.log("Ocurrió un error", err));
    }

    getAllTheOthers = (categoria) => {
        console.log('getAllTheOthers ');
        OddDonationService.getAlimentos(categoria)
            .then(otros => {
                console.log('otros : ', otros);
                this.setState({
                    otros: otros,
                })
            }
            )
            .catch(err => console.log("Ocurrió un error", err));
    }

    redirectToTargetBack = () => {
        console.log("...REDIRIGIR ATRAS");
        this.props.history.goBack();
    };

    redirectToTarget = (target) => {
        console.log("...REDIRIGIR A ", target);
        this.props.history.push(target);
    };


    render() {
        const divStyle = {
            fontSize: "xx-large"
        };
        return (
            <div>
                <div id="alimentos" className="pagina">
                    <div className="navbar navbar-fixed">
                        <nav>
                            <div className="nav-wrapper">
                                <h1 className="brand-logo logo-con-boton">
                                    Listado de alimentos
                            </h1>
                                <ul id="nav-mobile" className="left">
                                    <li>
                                        {/* <button
                                            className="accbtn"
                                            onClick={() => { this.redirectToBack() }}>
                                            <i className="material-icons fixed_eatc" style={divStyle}>
                                                chevron_left</i>
                                        </button> */}
                                        {/* <Link to="/dashboard" className="accbtn">
                                            <i className="material-icons fixed_eatc"
                                                style={divStyle}>
                                                chevron_left</i>
                                        </Link> */}
                                        <button
                                            className="backButton accbtn"
                                            onClick={() => { this.redirectToTargetBack() }}>
                                            <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                                        </button>
                                        {/* <Link to="#" className="accbtn" onClick={() => { this.redirectToTargetBack() }}>
                                            <i className="material-icons fixed_eatc" style={divStyle}>chevron_left</i>
                                        </Link> */}
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col s12 m10 offset-m1 l4 offset-l4">
                                <h3 className="opacity-54 left-align">
                                    Estos son los alimentos que puedes donar en la Alimentatón
                            </h3>
                                {this.state.showProgressBar && <Preloader />}
                                {this.state.showCollapsible &&
                                    <ul className="collapsible popout">
                                        <li className="active">
                                            <div className="collapsible-header">
                                                <i className="material-icons rotate right">expand_more</i>
                                                No perecederos
                                    </div>
                                            <div className="collapsible-body">
                                                <ul>
                                                    {
                                                        this.state.alimentos.map(alimento => {
                                                            if (alimento["categoria"] === 'No perecederos') {
                                                                return (
                                                                    <li className="collapsible-item" key={alimento["_id"]}>
                                                                        {alimento["alimentos"]}
                                                                    </li>
                                                                )
                                                            } else
                                                                return null
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="collapsible-header">
                                                <i className="material-icons rotate right">expand_more</i>
                                                Perecederos
                                    </div>
                                            <div className="collapsible-body">
                                                <ul>
                                                    {
                                                        this.state.alimentos.map(alimento => {
                                                            if (alimento["categoria"] === 'Perecederos') {
                                                                return (
                                                                    <li className="collapsible-item" key={alimento["_id"]}>
                                                                        {alimento["alimentos"]}
                                                                    </li>
                                                                )
                                                            } else
                                                                return null
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="collapsible-header">
                                                <i className="material-icons rotate right">expand_more</i>
                                                Otros
                                    </div>
                                            <div className="collapsible-body">
                                                <ul>
                                                    {
                                                        this.state.alimentos.map(alimento => {
                                                            if (alimento["categoria"] === 'Otros') {
                                                                return (
                                                                    <li className="collapsible-item" key={alimento["_id"]}>
                                                                        {alimento["alimentos"]}
                                                                    </li>
                                                                )
                                                            } else
                                                                return null
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row pad-bot-pag">
                            <div className="col s12 m10 offset-m1 l4 offset-l4">
                                <Link
                                    to="/dashboard"
                                    className="waves-effect waves-light btn-large accbtn">
                                    Ok, ¡entendido!
                            </Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- footer --> */}
                <footer className="page-footer footer-fixed" id="eatc-usr-fn">
                    <div className="container-footer">
                        <div className="row">
                            <div className="col l4 s4">
                                <button
                                    className="waves-effect waves-light btn accbtn"
                                    onClick={() => { this.redirectToTarget('/donacion_alimentos') }}>
                                    <i className="material-icons top">shopping_basket</i>
                                    <span>Donar Alimentos</span>
                                </button>
                                {/* <Link
                                    to="/donacion_alimentos"
                                    className="waves-effect waves-light btn accbtn">
                                    <i className="material-icons top">shopping_basket</i>
                                    <span>Donar Alimentos</span>
                                </Link> */}
                            </div>
                            <div className="col l4 s4 center-align">
                                <button
                                    className="waves-effect waves-light btn accbtn"
                                    onClick={() => { this.redirectToTarget('/voluntarios') }}>
                                    <i className="material-icons top">favorite_border</i>
                                    <span>Tiempo como voluntario</span>
                                </button>
                                {/* <Link
                                    to="/voluntarios"
                                    className="waves-effect waves-light btn accbtn">
                                    <i className="material-icons top">favorite_border</i>
                                    <span>Tiempo como voluntario</span>
                                </Link> */}
                            </div>
                            <div className="col l4 s4">
                                <button
                                    className="waves-effect waves-light btn accbtn"
                                    onClick={() => { this.redirectToTarget('/dinero') }}>
                                    <i className="material-icons top">credit_card</i>
                                    <span>Donar<br />dinero</span>
                                </button>
                                {/* <Link
                                    to="/dinero"
                                    className="waves-effect waves-light btn accbtn">
                                    <i className="material-icons top">credit_card</i>
                                    <span>Donar<br />dinero</span>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default eatc_alim_dona;
