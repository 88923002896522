//eatc_volunteers?correo_electronico=juan@eatcloud.com&numero_documento=8161174 
import API from "./config";

const RATIONS_ENDPOINTS = {
  ALL_FIELDS_VOLUNTEERS: "eatc_volunteers?_campos", //URI para llamar a todos los campos de voluntarios.
  ALL_VOLUNTEERS: "eatc_volunteers?numero_documento=_*", //URI para llamar a todos los voluntarios.
  VOLUNTEERS_INFO_EMAIL: "eatc_volunteers?correo_electronico=", //URI para llamar un voluntario por correo.
  VOLUNTEERS_INFO_DOC: "&numero_documento=", //URI para llamar un voluntario por documento.
};

const UserService = {
  getAll: () =>
    new Promise((resolve, reject) => {
      API.get(RATIONS_ENDPOINTS.ALL_VOLUNTEERS)
        .then(
          res => {
            console.log(res.data);
            console.log(res.status);
            console.log(res.statusText);
            return res.data.res
          }
        )
        .then(data => {
          resolve(data);
          console.log(data);
          console.log(data[0]);
        },
        )
        .catch(err => reject(err));
    }), // Funcion que nos devolverá el valor de todas las peliculas
  getVolunteer: (email, documento) =>
    new Promise((resolve, reject) => {
      API.get(RATIONS_ENDPOINTS.VOLUNTEERS_INFO_EMAIL 
        + email + RATIONS_ENDPOINTS.VOLUNTEERS_INFO_DOC + documento)
        .then(
          res => {
            console.log(res.data);
            console.log(res.status);
            console.log(res.statusText);
            return res.data.res
          }
        )
        .then(data => {
          resolve(data);
          console.log(data);
          console.log(data[0]);
        },
        )
        .catch(
          err => {
            console.log("error -> ", err);
            reject(err);
          });
    })
};

export default UserService;
