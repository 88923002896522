import React, { Component } from "react";
import { Link } from "react-router-dom";
import "materialize-css/dist/css/materialize.css";
import "../../css/design_system_styles.css";
import "../../css/eatcloud.css";
import "../../css/custom.css";
import "../../App.css";
import { remAll } from "../../services/auth";
//import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
//import Exito from "../../img/Exito.png";
//import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
//import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
//import imagenpuntodonacion from "../../img/img-pto-dona.jpg";
// Import Materialize
import M from "materialize-css";
import RacionesService from "../../services/RacionesService";
import PointDonationService from "../../services/PointDonationService";
import { isPoints, setPoints, getPoints, remPoints, isUser, getUser, isCitied, remCitied, setCitied, getCitied } from "../../services/auth";
import Preloader from '../preloader/Preloader';
import moment from 'moment';
import 'moment/locale/es'  // without this line it didn't work

export class eatc_alim_dsh extends Component {

  constructor(props) {
    super(props);
    this.state = {
      //points: props.location.state.points,
      showProgressBar: false,
      showProgressBarRations: false,
      showSelectMunicipios: false,
      showProgressBarPoints: false,
      points: [],
      rations: [],
      infoVolunteer: [],
      infoMunicipios: [],
      ciudad_residencia: "",
      error_ciudad_residencia: "",
      //value: ""
    };
    //this.options = ["Bogotá", "Medellín", "Cali"];
    //this.optionsMinus = ["bogota", "medellin", "cali"];
  }

  componentDidMount() {
    // Auto initialize all the things!
    console.log("...ESTAS EN /dashboard");
    M.AutoInit();
    this.getRationByDate(this.formatDate());
    this.getMunicipiosDonations(); //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION
    console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY PUNTOS?");
    if (isPoints()) {
      var points = JSON.parse(getPoints())
      console.log("...HAY PUNTOS EN EL LOCALSTORAGE Y LOS TOMO", points);
      console.log("...AGREGO PUNTOS EN EL STATE PUNTOS!");
      this.orderAndFirstObjectArray(points);
      this.setState({
        points: points,
      })
    } else {
      console.log("...NO HAY PUNTOS EN EL LOCALSTORAGE");
    }

    console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY USUARIO REGISTRADO?");
    if (isUser()) {
      var infoVolunteer = JSON.parse(getUser())
      console.log("...HAY USUARIO EN EL LOCALSTORAGE Y LOS TOMO", infoVolunteer);
      console.log("...AGREGO USUARIO EN EL STATE INFOVOLUTEER!");
      this.setState({
        infoVolunteer: infoVolunteer,
      })
    } else {
      console.log("...NO HAY USUARIO REGISTRADO EN EL LOCALSTORAGE");
    }

    //this.getRationByDate(this.formatDate());

    //this.getRationByDate('2019-10-15');
    //var elem = document.querySelector(".sidenav");
    /*var instance = M.Sidenav.init(elem, {
      edge: "left",
      inDuration: 250
    });*/
    //instance.open();
    console.log("...DIA SEGUN MOMENT", moment(new Date('2019-11-16')).add(1, 'days').locale('es').format('dddd LL'));
    console.log("...DIA SEGUN MOMENT", moment(new Date('2019-11-16')).locale('es').format('dddd LL'));
  }

  logOut = () => {
    console.log("...ELIMINANDO INFO LOCALSTORAGE");
    remAll();
    //this.redirectTo();
    this.redirectToTarget('/');
  }

  redirectToTarget = (target) => {
    console.log("...REDIRIGIR A ", target);
    this.props.history.push(target);
  };

  redirectToTerminos = () => {
    console.log("...REDIRIGIR A /terminos");
    this.props.history.push('/terminos');
  };

  redirectTo = () => {
    console.log("...REDIRIGIR A /");
    this.props.history.push('/');
  };

  setUser = () => {
    console.log("... (setUser) PREGUNTO EN EL STATE SI HAY USUARIO REGISTRADO?");
    //infoVolunteer
    //if (isUser()) {
    var volunteer = this.state.infoVolunteer;
    console.log("... (setUser) USUARIO ", volunteer);
    if (volunteer.length > 0) {
      console.log("... (setUser) HAY UN USUARIO");
      var currentVolunteer = volunteer[0];
      return (
        <ul id="slide-out" className="sidenav">
          <li>
            <div className="right">
              {/* <button
                className="waves-effect sidenav-close"
                onClick={() => { this.redirectToTarget('/dashboard') }}>
                <i className="material-icons">close</i>
              </button> */}
              <Link to="#" className="waves-effect sidenav-close" onClick={() => { this.redirectToTarget('/dashboard') }}>
                <i className="material-icons ">close</i>
              </Link>
              {/* <Link
                  to="/dashboard"
                  className="waves-effect sidenav-close">
                  <i className="material-icons">close</i>
                </Link> */}
            </div>

            <div className="user-view">
              <h2>{currentVolunteer["primer_nombre"]} {currentVolunteer["segundo_nombre"]} {currentVolunteer["primer_apellido"]} {currentVolunteer["segundo_apellido"]}</h2>
              <p><b>C.C.: </b>{currentVolunteer["numero_documento"]}</p>
              <p><b>Edad: </b>{currentVolunteer["edad"]} años</p>
              <p><b>Género: </b>{currentVolunteer["sexo"]}</p>
              <p><b>Ciudad: </b>{currentVolunteer["municipio_residencia"]}</p>
              <p><b>Telefono: </b>{currentVolunteer["numero_telefonico"]}</p>
              <p><b>Email: </b>{currentVolunteer["correo_electronico"]}</p>
              <p><b>Lugar Voluntariado: </b>{currentVolunteer["lugar_voluntariado"]}</p>
              <p><b>Dias: </b>{currentVolunteer["dias"]}</p>
              <p><b>Horario: </b>{currentVolunteer["horario_incio"]} a {currentVolunteer["horario_fin"]}</p>
            </div>
            <div className="user-view">
              <button
                className="waves-effect waves-light btn-large accbtn sidenav-close btn-text-overflow"
                onClick={this.logOut}>
                Salir de alimentatón app
                </button>
            </div>
          </li>
          <li>
            <div className="divider"></div>
          </li>
          <li>
            <div className="container title-center">
              <button
                className="waves-effect waves-teal btn-flat accbtn btn-text-overflow sidenav-close"
                onClick={() => { this.redirectToTarget('/terminos') }}>
                Términos de servicio
                </button>
              {/* <Link to="/terminos" className="sidenav-close">
                  Términos de servicio
                </Link> */}
            </div>
          </li>
        </ul>
      )
      /*} else {
        console.log("... (setUser) NO HAY USUARIO ");
        return (
          <ul id="slide-out" className="sidenav">
            <li>
              <div className="right">
                <button
                  className="waves-effect sidenav-close"
                  onClick={() => { this.redirectToTarget('/dashboard') }}>
                  <i className="material-icons">close</i>
                </button>
              </div>
              <div className="user-view">
                <button
                  className="waves-effect waves-light btn-large accbtn sidenav-close btn-text-overflow"
                  onClick={this.logOut}>
                  Salir de alimentatón app
                  </button>
              </div>
            </li>
            <li>
              <div className="divider"></div>
            </li>
            <li>
              <div className="container title-center">
                <button
                  className="waves-effect waves-teal btn-flat accbtn btn-text-overflow sidenav-close"
                  onClick={() => { this.redirectToTarget('/terminos') }}>
                  Términos de servicio
                  </button>
              </div>
            </li>
          </ul>
        )
      }*/
    } else {
      console.log("...NO TIENE USUARIO ");
      return (
        <ul id="slide-out" className="sidenav">
          <li>
            <div className="right">
              <Link to="#" className="waves-effect sidenav-close" onClick={() => { this.redirectToTarget('/dashboard') }}>
                <i className="material-icons ">close</i>
              </Link>
              {/* <button
                className="waves-effect sidenav-close"
                onClick={() => { this.redirectToTarget('/dashboard') }}>
                <i className="material-icons">close</i>
              </button> */}
            </div>
            <div className="user-view title-center">
              <h3>Debes ingresar tu usuario para poder acceder a tus datos como voluntario.</h3>
            </div>
            <div className="user-view">
              <button
                className="waves-effect waves-light btn-large accbtn sidenav-close"
                onClick={() => { this.redirectToTarget('/login') }}>
                Ingreso de usuario
              </button>
            </div>
          </li>
          <li>
            <div className="divider"></div>
          </li>
          <li>
            <div className="container title-center">
              <button
                className="waves-effect waves-teal btn-flat accbtn btn-text-overflow sidenav-close"
                onClick={() => { this.redirectToTarget('/terminos') }}>
                Términos de servicio
              </button>
            </div>
          </li>
        </ul>
      )
    }
  }

  formatDate = () => {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  //TODOS LOS MUNICIPIOS CON PUNTOS DE DONACION
  getMunicipiosDonations = () => {
    console.log('...OBTENGO TODOS LOS MUNICIPIOS CON PUNTO DE DONACION (ASINCRONO)');
    this.setState({
      showProgressBar: !this.state.showProgressBar
    });
    PointDonationService.getAll()
      .then(infoMunicipios => {
        console.log("@...OBTENGO MUNICIPIOS (EXITO) -> ", infoMunicipios);
        const distinctCities = [...new Set(infoMunicipios.map(x => x["eatc-city"]))];
        console.log("@...FILTRO MUNICIPIOS -> ", distinctCities);
        console.log("@...GUARDA EN EL STATE LOS MUNICIPIOS DONANTES");
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          infoMunicipios: distinctCities
        });
        console.log("...PREGUNTO SI HAY CIUDAD EN EL LOCALSTORAGE");
        if (isCitied()) {
          console.log("...SI, HAY CIUDAD EN EL LOCALSTORAGE");
          console.log("...getCitied()", getCitied());
          this.setState({
            ciudad_residencia: getCitied(),
            error_ciudad_residencia: "",
          });
        }else{
          console.log("...NO, NO HAY CIUDAD EN EL LOCALSTORAGE");
        }
      }
      )
      .catch(err => {
        console.log("@...NO OBTUVE MUNICIPIOS (ERROR) -> ", err);
        var infoMunicipios = [];
        this.setState({
          showProgressBar: !this.state.showProgressBar,
          infoMunicipios: infoMunicipios
        });
        if (isCitied()) {
          console.log("...SI, HAY CIUDAD EN EL LOCALSTORAGE");
          this.setState({
            ciudad_residencia: getCitied(),
            error_ciudad_residencia: "",
          });
        }
      });
  }

  getAllRations = () => {
    RacionesService.getAll()
      .then(rations =>
        this.setState({
          rations: rations
        })
      )
      .catch(err => {
        console.log("Ocurrió un error ", err)

      });
  };

  getDayandDate = (date, days) => {
    const arraydate = date.split(" ", 1).toString();
    console.log("...arraydate", arraydate);
    const arraydays = days.split(",");
    console.log("...arraydays", arraydays);
    this.orderDaysElementArray(arraydays);
    console.log("...arraydays order", arraydays);
    /*arraydays.forEach(function (day) {
      switch (day) {
        case "do":
          daysArray.push('Domingo');
          break;
        case "lu":
          daysArray.push('Lunes');
          break;
        case "ma":
          daysArray.push('Martes');
          break;
        case "mi":
          daysArray.push('Miercoles');
          break;
        case "ju":
          daysArray.push('Jueves');
          break;
        case "vi":
          daysArray.push('Viernes');
          break;
        case "sa":
          daysArray.push('Sábado');
          break;
        default:
          break;
      }
    });*/
    /*var ant = 0;
    for (let i = 1; i <= arraydays.length; i++) {
      ant=ant+1;
      //console.log("...arraydays[",ant,"]",arraydays[ant]);
      let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
      //console.log("...DAY", day);
      newArrayDay.push(day);
    }*/
    const newArrayDay = [];
    var i = 0;
    for (var j = 0; j < arraydays.length; j++) {
      console.log("...arraydays[",j,"]",arraydays[j]);
      if(j===0){
        i=i+1;
        let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
        console.log("...DAY", day);
        newArrayDay.push(day);
      }else if(j>0){
        console.log("...arraydays[",j-1,"]",arraydays[j-1]);
        switch (arraydays[j]) {
          case "do":
            console.log("...do",j);
            if(arraydays[j-1] === 'sa'){
              i=i+1;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'vi'){
              i=i+2;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ju'){
              i=i+3;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'mi'){
              i=i+4;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ma'){
              i=i+5;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'lu'){
              i=i+6;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            break;
          case "lu":
            console.log("...lu",j);
            if(arraydays[j-1] === 'do'){
              i=i+1;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'sa'){
              i=i+2;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'vi'){
              i=i+3;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ju'){
              i=i+4;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'mi'){
              i=i+5;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ma'){
              i=i+6;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            break;
          case "ma":
            console.log("...ma",j);
            if(arraydays[j-1] === 'lu'){
              i=i+1;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'do'){
              i=i+2;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'sa'){
              i=i+3;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'vi'){
              i=i+4;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ju'){
              i=i+5;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'mi'){
              i=i+6;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            break;
          case "mi":
            console.log("...mi",j);
            if(arraydays[j-1] === 'ma'){
              i=i+1;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'lu'){
              i=i+2;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'do'){
              i=i+3;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'sa'){
              i=i+4;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'vi'){
              i=i+5;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ju'){
              i=i+6;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            break;
          case "ju":
            console.log("...ju",j);
            if(arraydays[j-1] === 'mi'){
              i=i+1;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ma'){
              i=i+2;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'lu'){
              i=i+3;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'do'){
              i=i+4;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'sa'){
              i=i+5;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'vi'){
              i=i+6;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            break;
          case "vi":
            console.log("...vi",j);
            if(arraydays[j-1] === 'ju'){
              i=i+1;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'mi'){
              i=i+2;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ma'){
              i=i+3;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'lu'){
              i=i+4;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'do'){
              i=i+5;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'sa'){
              i=i+6;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            break;
          case "sa":
            console.log("...sa",j);
            if(arraydays[j-1] === 'vi'){
              i=i+1;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ju'){
              i=i+2;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'mi'){
              i=i+3;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'ma'){
              i=i+4;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'lu'){
              i=i+5;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            if(arraydays[j-1] === 'do'){
              i=i+6;
              let day = moment(new Date(arraydate)).add(i, 'days').locale('es').format('dddd LL');
              console.log("...DAY", day);
              newArrayDay.push(day);
            }
            break;
          default:
            break;
        }
      }
    }

    //console.log("newArrayDay \n", newArrayDay);
    return newArrayDay;
  }

  getDaysDonation = (date, days) => {
    const nomDaysArray = days.split(",");
    const daysArray = []
    nomDaysArray.forEach(function (day) {
      switch (day) {
        case "do":
          daysArray.push('Domingo');
          break;
        case "lu":
          daysArray.push('Lunes');
          break;
        case "ma":
          daysArray.push('Martes');
          break;
        case "mi":
          daysArray.push('Miercoles');
          break;
        case "ju":
          daysArray.push('Jueves');
          break;
        case "vi":
          daysArray.push('Viernes');
          break;
        case "sa":
          daysArray.push('Sábado');
          break;
        default:
          break;
      }
    });
    //let dayComplete = moment(new Date(arraydate)).locale('es').format('dddd');
    console.log("daysArray \n", daysArray);
    return daysArray;
  }

  getRationByDate = (fecha) => {
    console.log("...OBTENER RACIONES POR DIA (ASINCRONO)", fecha);
    this.setState({
      showProgressBarRations: !this.state.showProgressBarRations,
    })
    RacionesService.getRations(fecha)
      .then(rations => {
        console.log("@...OBTENGO RACIONES (EXITO) -> ", rations);
        console.log("@...SE GUARDAN RACIONES EN EL STATE RATIONS!");
        this.setState({
          showProgressBarRations: !this.state.showProgressBarRations,
          rations: rations
        })
      })
      .catch(err => {
        console.log("@...NO OBTUVE RACIONES (ERROR) -> ", err);
        console.log("...SE CREA UN OBJETO DUMMY CON INFO (0) RATIONS!");
        let errorRaciones = { _id: "0", dia: this.formatDate(), raciones_acumuladas_alimentaton: "0" };
        console.log("...ERROR RATIONS ", errorRaciones);
        console.log("...SE GUARDAN RACIONES EN EL STATE RATIONS!");
        this.setState({
          showProgressBarRations: !this.state.showProgressBarRations,
          rations: [...this.state.rations, errorRaciones]
        })
      });
  };

  getAllDonationPointsCity = (city) => {
    console.log('...OBTENGO TODOS LOS PUNTOS DE DONACION POR CITY (', city, ') (ASINCRONO)');
    this.setState({
      showProgressBarPoints: !this.state.showProgressBarPoints,
    })
    PointDonationService.getCity(city)
      .then(points => {
        console.log("@...OBTENGO PUNTOS (EXITO) -> ", points);
        console.log("@...GUARDA EN EL STATE POINTS");
        this.orderAndFirstObjectArray(points);
        this.setState({
          showProgressBarPoints: !this.state.showProgressBarPoints,
          points: points
        })
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY PUNTOS");
        if (isPoints()) {
          console.log("...SI HAY PUNTOS EN EL LOCALSTORAGE BORRO");
          remPoints();
          console.log("...AGREGO NUEVOS PUNTOS EN EL LOCALSTORAGE");
          setPoints(points);
        } else {
          console.log("...SI NO HAY PUNTOS EN EL LOCALSTORAGE AGREGO");
          setPoints(points);
        }

      })
      .catch(err => {
        console.log("@...NO OBTUVE PUNTOS (ERROR) -> ", err);
        const points = [];
        this.setState({
          showProgressBarPoints: !this.state.showProgressBarPoints,
          points: points
        })
        console.log("...PREGUNTO EN EL LOCALSTORAGE SI HAY PUNTOS");
        if (isPoints()) {
          console.log("...SI HAY PUNTOS EN EL LOCALSTORAGE BORRO");
          remPoints();
        }
      });
  };

  truncate = (str) => {
    return str.length > 27 ? str.substring(0, 7) + "..." : str;
  }

  orderAndFirstObjectArray = (items) => {
    console.log("...orderAndFirstObjectArray", items);
    items.sort(function (a, b) {
      //const a_bank = a["eatc-typology_a"].split("_", 1).toString();
      //const b_bank = b["eatc-typology_a"].split("_", 1).toString();
      var res = a["eatc-typology_a"].slice(0, 4);
      //console.log("...a_bank", a_bank);
      //console.log("...b_bank", b_bank);
      console.log("...res", res);
      /* if (a_bank === '') {
        console.log("...VACIO Y ULTIMO");
        return 1;
      }
      if (a_bank === '') {
        console.log("...VACIO Y ULTIMO");
        return 1;
      } */
      if (a["eatc-typology_a"] === '') {
        console.log("...VACIO Y ULTIMO");
        return 1;
      }
      if (res === 'BdeA') {
        console.log("...VACIO Y ULTIMO");
        return -1;
      }
      if (a["eatc-typology_a"] > b["eatc-typology_a"]) {
        console.log("...",a["eatc-typology_a"],"MAYOR QUE",b["eatc-typology_a"]);
        return 1;
      }
      if (a["eatc-typology_a"] < b["eatc-typology_a"]) {
        console.log("...",a["eatc-typology_a"],"MENOR QUE",b["eatc-typology_a"]);
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  orderAndFirstElementArray = (arr, item) => {
    arr.sort()
    var i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    return arr.unshift(item);
  }

  orderDaysElementArray = (arr) => {
    arr.sort(function (a, b) {
      if (a === 'vi') {
        console.log("...VACIO Y ULTIMO");
        return -1;
      }
      if (a === 'ju') {
        console.log("...VACIO Y ULTIMO");
        return 1;
      }
      // a must be equal to b
      return 0;
    });
  }

  onChange = e => {
    console.log("...ON CHANGE");
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const errorName = 'error_' + name;
    console.log('...target ', target);
    console.log('...name ', name);
    console.log('...value ', value);
    console.log('...errorName ', errorName);
    console.log("...GUARDA EN EL STATE ", name);
    this.setState({
      [name]: value,
      [errorName]: ""
    })
    if (name === "ciudad_residencia") {
      console.log('...LLAMA LA INFO DE ESE PUNTO ', name);
      console.log('...POINTS ', this.state.points);
      if (isCitied()) {
        remCitied();
        setCitied(value);
      }
      this.getAllDonationPointsCity(value);
      /*const currentPoint = this.state.points.filter(point => point["eatc-id"] === value);
      this.setState({
        currentPoint: currentPoint
      })*/
    }
  }

  render() {
    this.orderAndFirstElementArray(this.state.infoMunicipios, 'Bogotá');
    const options = this.state.infoMunicipios.map((elem, index) => (
      <option key={index} value={elem}>
        {elem}
      </option>
    ));
    return (
      <div>
        <div className="navbar">
          <nav>
            <div className="nav-wrapper">
              <h3 className="brand-logo" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Elige tu punto de donación</h3>
              <ul id="nav-mobile" className="right">
                <li>
                  <Link
                    to="/slide-out"
                    className="sidenav-trigger">
                    <i className="material-icons">person_outline</i>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          {/* <!--     menu lat --> */}
          {
            this.setUser()
          }
          {/* <ul id="slide-out" className="sidenav">
            <li>
              <div className="user-view">
                <h1>Luis Carlos Correa</h1>
              </div>
            </li>
            <li>
              <div className="divider"></div>
            </li>
            <li>
              <div className="container">
                <p>Tu código único de donación</p>
                <p className="big fucsia left-align" id="codigo-dona">
                  cek02156f
                </p>
              </div>
            </li>
            <li>
              <div className="divider"></div>
            </li>
            <li>
              <div className="container">
                <Link to="/terminos" className="sidenav-close">
                  Términos de servicio
                </Link>
              </div>
            </li>
            <li>
              <div className="divider"></div>
            </li>
          </ul> */}
        </div>

        {/* <!-- mapa --> */}
        <div id="map-canvas-big">
          {/* <!-- score --> */}
          <div className="container">
            {/* <!-- buscador --> */}
            {/* <div className="barra-busqueda-mapa">
              <form className="map-search" id="mapSearch">
                <input
                  placeholder="Buscar..."
                  type="text"
                  className="map-search-input"
                  id="mapSearchInput"
                />
                <button
                  type="submit"
                  className="map-search-submit"
                  id="mapSearchSubmit"
                >
                  Searchs
                </button>
              </form>
            </div> */}
            {/* <!-- CAMBIAR CIUDAD --> */}
            <div className="barra-busqueda-mapa">
              {/* <div className="col s10 offset-s1 m10 offset-m1 l4 offset-l4"> */}
              {this.state.showProgressBar && <Preloader />}
              {!this.state.showProgressBar &&
                <div className="input-field">
                  <select
                    name='ciudad_residencia'
                    className="browser-default select-dsh" //browser-default
                    onChange={this.onChange}
                    value={this.state.ciudad_residencia}>
                    <option value="" disabled>Cambiar ciudad de residencia *</option>
                    {options}
                  </select>
                  {this.state.error_ciudad_residencia && <p style={{ color: 'red' }}>{this.state.error_ciudad_residencia}</p>}
                </div>}
            </div>
            <div className="card-metrica-azul mini-card score-in-map">
              <div className="tit-card small">Platos de comida donados en el país</div>
              {this.state.showProgressBarRations && <Preloader />}
              {!this.state.showProgressBarRations &&
                this.state.rations.map(ration => {
                  return (<div className="metrica-card" key={ration["_id"]}>
                    {ration["raciones_acumuladas_alimentaton"]}
                  </div>)
                })
              }
            </div>
          </div>
        </div>

        {/* <!-- swipe de puntos de donacion --> */}
        <div className="card-swipe">
          <div className="container">
            <div className="row">
              <div className="col s12 m10 offset-m1 l4 offset-l4">
                <br /><br />
                {this.state.showProgressBarPoints && <Preloader />}
                {!this.state.showProgressBarPoints &&
                  <div className="table-wrapper">
                    <table>
                      <tbody>
                        <tr>
                          {
                            this.state.points.map(point => {
                              return (
                                <td key={point["_id"]}>
                                  {/* <!-- card --> */}
                                  <div className="img-card-min">
                                    {(point["eatc-typology_a"] === '' ||
                                      point["eatc-typology_a"] === ' ' ||
                                      point["eatc-typology_a"] === 'bdea' ||
                                      point["eatc-typology_a"] === 'BdeA' ||
                                      point["eatc-typology_a"] === 'Otros' ||
                                      point["eatc-typology_a"] === 'otros' ||
                                      point["eatc-typology_a"] === '0' ||
                                      point["eatc-typology_a"] === 0 ||
                                      point["eatc-typology_a"] === null ||
                                      point["eatc-typology_a"] === undefined) ?
                                      <img
                                        className="responsive-img card-img"
                                        //src={`../../img/${point["eatc-typology_a"]}.png`}
                                        src={require('../../img/logo alimentaton@2x.png')}//point["eatc-typology_a"]
                                        alt="imagenpuntodonacion" />
                                      :
                                      <img
                                        className="responsive-img card-img"
                                        //src={`../../img/${point["eatc-typology_a"]}.png`}
                                        src={require('../../img/' + point["eatc-typology_a"].trim().toLowerCase() + '.png')}//point["eatc-typology_a"] .replace(/[^-A-Za-z0-9]+/g, '')
                                        alt="imagenpuntodonacion" />
                                    }
                                    <h3>{point["eatc-name"]}</h3>
                                    {/* <p className="meta">{point["eatc-adress"]}</p> */}
                                    <span><b>Dirección: </b></span>
                                    <p className="">
                                      <a href={"https://www.google.com/maps/dir/?api=1&destination=" + point['eatc-lat'].replace(",", ".") + "," + point['eatc-lon'].replace(",", ".") + "&travelmode=DRIVING"}
                                        className=""
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        {point["eatc-adress"]}
                                      </a>
                                    </p>
                                    <p className="">
                                      <span><b>Teléfono: </b><br />{point["eatc-phone"]}</span>
                                    </p>
                                    <p className="warning">
                                      <span><b>Horarios: </b><br />{point["horario_inicio"]} - {point["horario_final"]}</span>
                                    </p>
                                    <span><b>Dias: </b></span>
                                    {this.getDayandDate(point["fecha"], point["dias"]).map((ration, index) => {
                                      return (<p key={index}><span>{ration}</span><br /></p>)
                                    })}
                                  </div>
                                </td>
                              )
                            })
                          }
                        </tr>
                      </tbody>
                    </table>
                  </div>}
                <div className="container pad-bot-pag">
                  <div className="row">
                    <div className="col s12 m10 offset-m1 l4 offset-l4">
                      <button
                        className="waves-effect waves-light btn-large accbtn btn-violeta btn-text-overflow"
                        onClick={() => { this.redirectToTarget('/donacion_alimentos') }}>
                        ¿Qué alimentos puedes donar?
                      </button>
                      {/* <Link
                        to="/donacion_alimentos"
                        className="waves-effect waves-light btn-large accbtn btn-violeta btn-text-overflow">
                        ¿Qué alimentos puedes donar?
                      </Link> */}
                    </div>
                    <div className="col s12 m10 offset-m1 l4 offset-l4">
                      <br />
                      <button
                        className="waves-effect waves-teal btn-flat accbtn btn-text-overflow"
                        onClick={() => { this.redirectToTarget('/terminos') }}>
                        Conoce los términos de servicio
                      </button>
                      <br />
                      <p>
                        Si tienes inquietudes comunicate al
                        <a href="tel:+14029305"
                          className=""
                          target="_blank"
                          rel="noopener noreferrer"> (1) 4029305 </a>
                        ,
                        <a href="tel:+573132457978"
                          className=""
                          target="_blank"
                          rel="noopener noreferrer"> 3132457978 </a>
                        o escríbenos
                        <a href="mailto:comunicaciones@abaco.org.co"
                          className=""
                          target="_blank"
                          rel="noopener noreferrer"> comunicaciones@abaco.org.co </a>
                        o
                        <a href="mailto:alimentaton@abaco.org.co"
                          className=""
                          target="_blank"
                          rel="noopener noreferrer"> alimentaton@abaco.org.co </a>
                      </p>
                      {/* <br /><Link
                        to="/terminos"
                        className="waves-effect waves-teal btn-flat accbtn">
                        Conoce los términos de servicio
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- footer --> */}
        <footer className="page-footer footer-fixed" id="eatc-usr-fn">
          <div className="container-footer">
            <div className="row">
              <div className="col l4 s4">
                <button
                  className="waves-effect waves-light btn accbtn"
                  onClick={() => { this.redirectToTarget('/donacion_alimentos') }}>
                  <i className="material-icons top">shopping_basket</i>
                  <span>Donar Alimentos</span>
                </button>
                {/* <Link
                    to="/donacion_alimentos"
                    className="waves-effect waves-light btn accbtn">
                    <i className="material-icons top">shopping_basket</i>
                    <span>Donar Alimentos</span>
                  </Link> */}
              </div>
              <div className="col l4 s4 center-align">
                <button
                  className="waves-effect waves-light btn accbtn"
                  onClick={() => { this.redirectToTarget('/voluntarios') }}>
                  <i className="material-icons top">favorite_border</i>
                  <span>Tiempo como voluntario</span>
                </button>
                {/* <Link
                    to="/voluntarios"
                    className="waves-effect waves-light btn accbtn">
                    <i className="material-icons top">favorite_border</i>
                    <span>Tiempo como voluntario</span>
                  </Link> */}
              </div>
              <div className="col l4 s4">
                <button
                  className="waves-effect waves-light btn accbtn"
                  onClick={() => { this.redirectToTarget('/dinero') }}>
                  <i className="material-icons top">credit_card</i>
                  <span>Donar<br />dinero</span>
                </button>
                {/* <Link
                    to="/dinero"
                    className="waves-effect waves-light btn accbtn">
                    <i className="material-icons top">credit_card</i>
                    <span>Donar<br />dinero</span>
                  </Link> */}
              </div>
            </div>
          </div>
        </footer>
      </div >
    );
  }
}

export default eatc_alim_dsh;
